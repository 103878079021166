import React from 'react'
import { useState, useEffect  } from 'react';
import {Link} from 'react-router-dom'
// import { Col, Container, Row,Form } from 'react-bootstrap'
// import Footer from '../Components/Footer';
import { FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Client1 from '../asset/img/Amardeep.webp'
import {Row,Col,Container,Figure,Card} from 'react-bootstrap';
import Result1 from '../asset/img/sec-10.jpg'
import Result2 from '../asset/img/sec-11.jpg'
import Blogimg1 from '../asset/img/blog-img1.png'
import Blogimg2 from '../asset/img/blog-img2.jpg'
import Blogimg3 from '../asset/img/blog-img3.png'
import  { Redirect } from 'react-router-dom'
import emailjs from 'emailjs-com';
import Footer from '../Components/Footer';
import Testimonial from './testimonial';
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from 'react-helmet-async';

// import {  } from '@mui/material/utils';
const PreviousBtn = (props) => {
    console.log(props);
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleLeft style={{ color: "white", fontSize: "23px" }} />
      </div>
    );
  };
  const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleRight style={{ color: "white", fontSize: "23px" }} />
      </div>
    );
  };



const Contactus = () => {

    const client = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

     const initialValues = {name:null, email:null,phonenumber:null,message:null,recaptcha:null}; 
     const [formValues, setFormValues] = useState(initialValues);
     const [formErrors, setFormErrors] = useState({});
     const [isSubmit, setIsSubmit] = useState(false);
     const [isVerified, setVerified] = useState(null);  
     const handleChange = (e) => {
         
         const {name, value } = e.target;
         setFormValues({ ...formValues, [name]:value});
         
     };
         
     const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
        // if (this.state.verified) {
        //     fetch()
        //     .then(this.handleSuccess)
        //     .catch(error => alert(error))
        //     .preventDefault()
        // }
        // else {this.setState({reply: "Please verify the ReCaptcha."})}
        React.useEffect(() => {
            // register({ name: "spam" }, { required: "prove you are no bot plz!" });
            }, []);

            const handleRecaptcha = value => {
                console.log("Captcha value:", value);
                setVerified(value);
                };
    
     const handleSubmit = (e) =>{
         e.preventDefault();
         setFormErrors(validate(formValues)); 
         setIsSubmit(true);
         if(Object.keys(validate(formValues)).length === 0){
         
         emailjs.sendForm('service_5s23yq8', 'template_4wb7y7b', e.target, 'ukV0_dkxOVar5bwnV')
         .then((result) => {
             console.log(result.text);
         }, (error) => {
             console.log(error.text);
         });
        }
     };
     useEffect(() =>{
        if(Object.keys(formErrors).length === 0 && isSubmit)
        {
            
            console.log(formValues);
        }
     },[formErrors])

     const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const phone = /^[6-9]\d{9}$/;
        
        if(!values.name){
            errors.name = "please enter your name";
        }
        if(!values.email){
            errors.email = "please enter your email address";
        } else if(!regex.test(values.email)){
            errors.email = "this is not a valid email format";
        }

        if(!values.phonenumber){
            errors.phonenumber = "please enter your phone number";
        } 
        else if(!phone.test(values.phonenumber)){
            errors.phonenumber = "please enter your valid phone number";
        }
        if(!values.message){
            errors.message = "please enter your message";
        }
        // if(values.recaptcha == ""){
        //     errors.recaptcha = "The Captcha field cannot be blank. Please enter a value";
        // }
        
       return errors; 
     };
  
    
    return (
        <div>
            <Helmet>
                <title>Contact us | digiDZN | Best digital markeitng agency</title>
                <meta name="description" content="Looking fro a Digital Marketing Agency to Grow your Business Online? Chat with experts at our DigiDZN website. Or Contact us at 9868877018."/>
            </Helmet>
            <div className="contactus-bg">
                <Container >
                    <Row >
                        {/* <Col md={}></Col> */}
                        <Col md={6} className="contactus-heading" >
                            <div className="space-100"></div>
                        <span style={{color:'white',fontSize:'40px',fontWeight:'500',fontFamily:'Roboto,sans-serif'}}>Get in touch with us</span><br/>
                            <h1 >
                             & get an E-book free or Digital <br/>Marketing for business<br/> owners
                            </h1>
                           
                            <p>
                            We love connecting with the people and sharing creative
                             ideas and thoughts every time you’ll connect with us!
                              Feel free to connect with us anytime here or send your query to us.
                               We’ll get back to you super soon.
                            </p>
                            <h6 className="mt-5">
                            digiDZN - Digital Marketing Company in Delhi
                            </h6>
                            <h6>Address - G-4, 1st Floor, Lawrence Road Industrial Area.</h6>
                            <h6>
                            Phone Number - 9868877018
                            </h6>
                           
                        </Col>
                        <Col md={6} className="contact-form-bg mt-5">
                                <h1>
                                Ready to chat with our growth experts?
                                </h1>
                                {/* <pre>{JSON.stringify(formValues,undefined,2)}</pre> */}

                                
                                <form className="contactform" onSubmit={handleSubmit}>
                                    <input type='text'
                                           
                                           name="name"
                                           value={formValues.name}
                                           onChange={handleChange}
                                           placeholder="Name" 
                                    />
                                    <p className="mb-4">{formErrors.name}</p>
                                    <input type="email"
                                           
                                           name="email" 
                                           value={formValues.email}
                                           onChange={handleChange}
                                           placeholder="email"
                                    />
                                    <p className="mb-4">{formErrors.email}</p>
                                    <input type="tel" 
                                           onKeyPress="if(this.value.length==10) return false;"
                                           name="phonenumber" 
                                           value={formValues.phonenumber}
                                           onChange={handleChange}
                                           placeholder="phone number"
                                    />
                                    <p className="mb-4">{formErrors.phonenumber}</p>
                                    <textarea 
                                           className="mb-3" 
                                           placeholder="message"
                                           name="message"
                                           value={formValues.message}
                                           onChange={handleChange}
                                          >

                                    </textarea>
                                    <p className="mb-4">{formErrors.message}</p>
                                    <ReCAPTCHA className="mb-4"
                                        sitekey="6LdqGGQaAAAAABS5Vtjm1NTJ9HdMK8rKjR96V0gE"
                                        onChange={handleRecaptcha }
                                        // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                        
                                    />
                                     {/* <ReCAPTCHA
                                    className="g-recaptcha"
                                    sitekey="6LdqGGQaAAAAABS5Vtjm1NTJ9HdMK8rKjR96V0gE"
                                    onChange={this.onChange}
                                    theme="dark"
                                />
                                { this.state.verified ? <p id="error" className="error">{this.state.reply}</p> : null }
                                { this.state.showSuccess ? <p id="success" className="success">Thank you, we will be in touch asap.</p> : null } */}
                                    {/* <p className="mb-4">{formErrors.recaptcha}</p>    */}
                                    <button disabled={!isVerified} className="contact-submit-btn">send</button>
                                </form>
                                {Object.keys(formErrors).length === 0 && isSubmit ? (<Redirect to='/thank-you-page'  />): (<div></div>) }
                        </Col>                        
                    </Row>
                </Container>
            </div>
            <div style={{width:"100%"}}>
                
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d133230.48877538994!2d77.15415541653864!3d28.658742976553246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03aabadf3177%3A0xa362558c1bd55596!2sdigiDZN!5e0!3m2!1sen!2sin!4v1635436456225!5m2!1sen!2sin" width="100%" height="460"   loading="lazy"></iframe>
                  
                </div>
              {/* section4-client testimonial */}
        <Testimonial />
                      {/* section4 full growth */}
        <div>
            <Container>
                <Row>
                    <Col className="text-center heading-full-growth">
                    <h1>
                    Full Stack Growth
                    </h1>
                    </Col>
                </Row>
            </Container>
            </div>
            <div className="full-growth-bg">
            <Container  className="mt-2 ">
                <Row xs="3">
                    <Col  md={4} xs={12}>
                    <div className="full-growthcontent"> 
                    <h6>
                        Academy
                    </h6>
                    <p>
                    Come, learn & intern! digiDZN brings to you courses in 
                    Digital Marketing & eCommerce to make you industry ready.
                     digiDZN helps you get placed post your training.
                    </p>
                    </div>
                    </Col>
                    <Col md={4} xs={12}>
                    <Link to='/seo' className="text-none-underline">
                    <div className="full-growthcontent"> 
                    <h6>
                    SEO
                    </h6>
                    <p>
                    Do you want to execute modern yet effective 
                    SEO to power your business strategy? Let us
                     help you to deliver what matters for your industry!
                    </p>
                    </div>
                    </Link>
                    </Col>
                    
                    
                    <Col md={4} xs={12}>
                    <Link to='/socialmedia' className="text-none-underline">
                    <div className="full-growthcontent"> 
                    <h6>
                    Social Media
                    </h6>
                    <p>
                    Finding new ways to interact and engage with the audience of your brand?
                     Still not sure how to execute it? 
                     Let us assist you with befitting strategy and approach.
                    </p>
                    </div>
                    </Link>
                    </Col>
                    
                    
                    <Col  md={4} xs={12}>
                    <Link to='/campaigns' className="text-none-underline">
                    <div className="full-growthcontent"> 
                    <h6>
                    Campaigns
                    </h6>
                    <p>
                    Do you know what is essential to get quality leads?
                     A good campaign strategy! Let us plan some terrific 
                     campaign strategies to make your brand go viral.
                    </p>
                    </div>
                    </Link>
                    </Col>
                    <Col md={4} xs={12}>
                    <div className="full-growthcontent"> 
                    <h6>
                    E-Commerce
                    </h6>
                    <p>
                    Figuring out ways to generate sales on your e-commerce platforms? 
                    Well, we are here to expand your e-commerce
                     business following all the algorithms and guidelines.
                    </p>
                    </div>
                    </Col>
                    <Col md={4} xs={12}>
                    <div className="full-growthcontent"> 
                    <h6>
                    PR
                    </h6>
                    <p>
                    Do you know PR strategy is vital for a business 
                    to establish a strong reputation? With the right 
                    PR strategy, brand visibility can be highly effective.
                    </p>
                    </div>
                    </Col>
                    
                </Row>
                <Row>
                    <Col className="text-center mt-5 mb-5">
                    <Link to="/services"> <button className="growth-button">Full Growth Capabilities</button></Link>
                    </Col>
                </Row>
            </Container>

            
        </div>

        <div className="bg-result mt-1">
            <Container>
                <Row>
                    <Col className="text-center mt-1 heading-full-growth">
                    <h1>
                    Consistently Delivering Results
                    </h1>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col className="mt-5 ">
                        <div className="bgcolor-result"> 
                                    <img
                                    className="d-block img-result border-result"
                                    src={Result1}
                                    alt="Sec-10"
                                    />
                        </div>
                    </Col>
                    <Col className="mt-5">
                        <div className="bgcolor-result">
                                   <img
                                    className="d-block border-result img-result"
                                    src={Result2}
                                    alt="Sec-10"
                                    />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-5">
                    <Link to="/results"> <button className="growth-button">Read more</button></Link>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col className="text-center mt-5 heading-full-growth">
                    <h1>
                    Learning Bytes
                    </h1>
                    </Col>
                </Row>
            </Container>
        </div>
        <div>
            <Container>
                <Row>
                    <Col md={4}> 
                    <Card className="border-card" style={{ width: "100%" }}>
                        <Card.Body>
                        <Card.Img variant="top" class="blog-img" src={Blogimg1} />
                        <Card.Title className="mt-2 blog-title">HOW TO USE FACEBOOK VIDEO ADS TO GROW YOUR BUSINESS</Card.Title>
                        <Card.Subtitle className="mb-2 mt-2 blog-sub">
                        September 24, 2021
                        </Card.Subtitle>
                        <Card.Text className="mb-2 mt-2 blog-text">
                        We’re presuming you already know that videos are the way
                        to go and that you’ll need to run Facebook advertising. And this implies that you’re
                        </Card.Text>
                        <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                        </Card.Body>
                    </Card>
                    </Col>
                <Col md={4}>
                <Card className="border-card" style={{ width: "100%" }}>
                    <Card.Body>
                    <Card.Img variant="top" class="blog-img" src={Blogimg2} />
                    <Card.Title className="mt-2 blog-title">Are Facebook ads worth it? (Yes, here’s why)</Card.Title>
                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                    September 20, 2021
                    </Card.Subtitle>
                    <Card.Text className="mb-2 mt-2 blog-text">
                    Facebook seems to have run its course. With people moving towards 
                    newer social media platforms and with all the false media, it may look like
                    </Card.Text>
                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                    </Card.Body>
                </Card>
                </Col>
                <Col md={4}>
                <Card className="border-card" style={{ width: "100%" }}>
                    <Card.Body>
                    <Card.Img variant="top" class="blog-img" src={Blogimg3} />
                    <Card.Title className="mt-2 blog-title">Best Apps for Social Media Marketing 2021</Card.Title>
                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                    August 28, 2021
                    </Card.Subtitle>
                    <Card.Text className="mb-2 mt-2 blog-text">
                    Social media is necessary for both marketers and consumers, 
                    whether you like it or not. People quickly realized how crucial it was to maintain a
                    </Card.Text>
                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                    </Card.Body>
                </Card>
                </Col>
                </Row>
            </Container>
        </div>
        {/* section6-ready to kickoff  http://www.w3.org/2000/svg*/}
        <div class="custom-shape-divider-top-1635236144 mt-10">
            <svg data-name="Layer 1" xmlns="" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
            </svg>
        </div>
       <div className="ready-kick-bg">
    
            <Container >
                <Row>
                    <Col className="text-center sec-6-head">
                    <h1>Ready To Kick Off Your Growth Journey</h1>
                    <Link to="/contact-us" onRowClick={scrollToTop()} ><button className="ready-kick-btn mt-5">SPEAK TO DIGIDZN</button></Link>
                    </Col>

                </Row>
            </Container>
        </div>

                <Footer />
        </div>
    )
}

export default Contactus
