import React from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Result1 from '../asset/img/result1.png'
import Result2 from '../asset/img/result2.png'
import Result3 from '../asset/img/result3.png'
import Result4 from '../asset/img/result4.png'
import Famphy from '../asset/img/sec-11.jpg'
import Milton from '../asset/img/sec-10.jpg'
import Maven from '../asset/img/Maven.jpg'
import Deco from '../asset/img/Deco.jpg'
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet-async';
function Results() {
    

        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoPlay:true,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };
    
          const client = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };
    return (
        <div>
            <Helmet>
                <title>Digital Marketing services company in Delhi | Digital Marketer | digiDZN</title>
                <meta name="description" content="India's #1 Digital Marketing Services. digiDZN offers a broad range of digital marketing services in Delhi including branding and strategy, social media marketing, Pay-per-click, Display advertising, and more."/>
            </Helmet>
            <div className="section3-carousel">
            <Container>
                <Row>
                    <Col className="text-center sec-2-carousel mt-10">
                    

                    <Slider className="slider-mob-mt" {...settings}>
          <div>
            <img
                className="d-block img-icon"
                src={Result1}
                alt="Result1"
                />
            
          </div>
          <div>
          <img
                className="d-block img-icon"
                src={Result2}
                alt="Result2"
                />
          </div>
          <div>
          <img
                className="d-block img-icon"
                src={Result3}
                alt="Result3"
                />
          </div>
          <div>
          <img
                className="d-block img-icon"
                src={Result4}
                alt="Result4"
                />
          </div>
          
        </Slider>
                    </Col>
                </Row>
            </Container>
        </div> 
        {/* section1 */}
        <div className="mt-10">
            <Container>
                <Row>
                    <Col md={6}>
                                <img
                                    className="d-block  img-result-page"
                                    src={Famphy}
                                    alt="Famphy"
                                    />
                    </Col>
                    <Col md={6} className="result-page-heading">
                        <h1>
                        Famphy: Doctor on Call for non-critical illness
                        </h1>
                        <p>
                        Famphy being a startup had a very sales oriented approach & 
                        needed a consistent flow of daily leads & calls to sustain their
                         marketing expenses. What did we achieve? High Organic Traffic, 
                         Keyword Rich Content & a lower Customer Acquisition Cost. 
                         How did we achieve it? Click on Read More to find out!
                        </p>
                        <Link to='/famphy-doctor-on-call-for-non-critical-illness'>  <button className="growth-button mt-10">READ MORE</button></Link>
                    </Col>
                    
                </Row>
            </Container>
        </div>
        {/* section2 */}
        <div className="mt-10">
            <Container>
                <Row>
                    <Col md={6}>
                                <img
                                    className="d-block  img-result-page"
                                    src={Milton}
                                    alt="Milton"
                                    />
                    </Col>
                    <Col md={6} className="result-page-heading">
                        <h1>
                        Milton Home Appliances: Premium Kitchen Products
                        </h1>
                        <p>
                        Milton Home Appliances is a household name PAN India 
                        with thousands of happy customers over the last few decades. 
                        Check out our case study on how we managed to penetrate
                         the highly competitive eCommerce market by having an edge over
                          our competitors using just the right strategies!
                        </p>
                        <Link to='/milton-home-appliances-premium-kitchen-products'> <button className="growth-button mt-10">READ MORE</button></Link>
                    </Col>
                    
                </Row>
            </Container>
        </div>
        {/* section3 */}
        <div className="mt-10">
            <Container>
                <Row>
                    <Col md={6}>
                                <img
                                    className="d-block  img-result-page"
                                    src={Maven}
                                    alt="Maven"
                                    />
                    </Col>
                    <Col md={6} className="result-page-heading">
                        <h1>
                        Maven Stainless Steel
                        </h1>
                        <p>
                        Maven stainless is a leading manufacturer of Stainless Steel pipes & tubes
                         having products in every desired shape & size. What did we achieve? High 
                         Organic Traffic, Industry Visibility, Repeat orders, Target Audience Engagement. 
                         How did we achieve it? Click on Read More to find out!
                        </p>
                       <Link to='/maven-stainless-steel'> <button className="growth-button mt-10">READ MORE</button></Link>
                    </Col>
                    
                </Row>
            </Container>
        </div>
        {/* section4 */}
        <div className="mt-10">
            <Container>
                <Row>
                    <Col md={6}>
                                <img
                                    className="d-block  img-result-page"
                                    src={Deco}
                                    alt="Deco"
                                    />
                    </Col>
                    <Col md={6} className="result-page-heading">
                        <h1>
                        Deco-Arte
                        </h1>
                        <p>
                        Deco-Arte is an interior designing studio that provides complete 
                        interior design solutions for restaurants, offices, hotels, homes, 
                        and retail spaces. Check out our case study on how we generate leads
                         in a highly competitive market.
                        </p>
                        <Link to='/deco-arte'><button className="growth-button mt-10">READ MORE</button></Link>
                    </Col>
                    
                </Row>
            </Container>
        </div>
        {/* section5  */}
        <div>
          
            </div>
            <div className="full-growth-bg">
            <Container  className="mt-2 ">
                <Row xs="3">
                    <Col  md={4} xs={12}>
                    <div className="full-growthcontent"> 
                    <h6>
                        Academy
                    </h6>
                    <p>
                    Come, learn & intern! digiDZN brings to you courses in 
                    Digital Marketing & eCommerce to make you industry ready.
                     digiDZN helps you get placed post your training.
                    </p>
                    </div>
                    </Col>
                    <Col md={4} xs={12}>
                    <Link to='/seo' className="text-none-underline">
                    <div className="full-growthcontent"> 
                    <h6>
                    SEO
                    </h6>
                    <p>
                    Do you want to execute modern yet effective 
                    SEO to power your business strategy? Let us
                     help you to deliver what matters for your industry!
                    </p>
                    </div>
                    </Link>
                    </Col>
                    
                    
                    <Col md={4} xs={12}>
                    <Link to='/socialmedia' className="text-none-underline">
                    <div className="full-growthcontent"> 
                    <h6>
                    Social Media
                    </h6>
                    <p>
                    Finding new ways to interact and engage with the audience of your brand?
                     Still not sure how to execute it? 
                     Let us assist you with befitting strategy and approach.
                    </p>
                    </div>
                    </Link>
                    </Col>
                    
                    
                    <Col  md={4} xs={12}>
                    <Link to='/campaigns' className="text-none-underline">
                    <div className="full-growthcontent"> 
                    <h6>
                    Campaigns
                    </h6>
                    <p>
                    Do you know what is essential to get quality leads?
                     A good campaign strategy! Let us plan some terrific 
                     campaign strategies to make your brand go viral.
                    </p>
                    </div>
                    </Link>
                    </Col>
                    <Col md={4} xs={12}>
                    <div className="full-growthcontent"> 
                    <h6>
                    E-Commerce
                    </h6>
                    <p>
                    Figuring out ways to generate sales on your e-commerce platforms? 
                    Well, we are here to expand your e-commerce
                     business following all the algorithms and guidelines.
                    </p>
                    </div>
                    </Col>
                    <Col md={4} xs={12}>
                    <div className="full-growthcontent"> 
                    <h6>
                    PR
                    </h6>
                    <p>
                    Do you know PR strategy is vital for a business 
                    to establish a strong reputation? With the right 
                    PR strategy, brand visibility can be highly effective.
                    </p>
                    </div>
                    </Col>
                    
                </Row>
                <Row>
                    <Col className="text-center mt-5 mb-5">
                    <Link to="/services"> <button className="growth-button">Full Growth Capabilities</button></Link>
                    </Col>
                </Row>
            </Container>

            
        </div>
          {/* section6-ready to kickoff  http://www.w3.org/2000/svg*/}
           <div class="custom-shape-divider-top-1635236144 mt-10">
                <svg data-name="Layer 1" xmlns="" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>
       <div className="ready-kick-bg">
    
            <Container >
                <Row>
                    <Col className="text-center sec-6-head">
                    <h1>Ready To Kick Off Your Growth Journey</h1>
                    <Link to="/contact-us"><button className="ready-kick-btn mt-5">SPEAK TO DIGIDZN</button></Link>
                    </Col>

                </Row>
            </Container>
        </div>
    <Footer/>


        </div>
    )
}

export default Results
