import axios from 'axios';
import Footer from '../Components/Footer';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container, Figure, Card, Form,FormControl,Button } from 'react-bootstrap';
import { useParams, useRouteMatch, useLocation, useHistory, Link  } from 'react-router-dom';
import Blogimg from '../asset/img/blogpost1.jpeg';
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser, faClock, faCommentDots, faSearch } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { FaRegCalendarAlt} from 'react-icons/fa'
import spinner from '../asset/loader/Spinner-1s-200px.gif'
import {valueEmptyCheck,emailValidator,validateWebsite} from '../util/validation'
import { postRequest, MD5Hash, BLOG_LIST, BASE_IMAGE_URL, 
  CATEGORY_DETAILS,BLOG_VIEW,salt,BLOG_CATEGORY_LISTS, BLOG_RECENT_POST,BLOG_CREATE_COMMENT, BLOG_COMMENT_LIST,BLOG_RECENT_COMMENTS,BLOG_CATEGORY_LIST,BLOG_SEARCH
} from '../util/api';
const BlogView = (data) => {
// const [date , setDate] = {}

  const location = useLocation()
  const [blogview, setBlogview] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);

  const [blogs_list, setBlogsList] = useState([]);

  const [blogCommentList, setBlogCommentList] = useState([]);
  const [socialIcons, setSocialIcons] = useState({
    social:[]
  });
const [loader,setLoader] = useState(true)
  const path = location.pathname.split('/');
  let id = parseInt(path[2]);

  
 
  const [recent_blogs, setRecentBlogs] = useState({
    recent:[]
  });
  const [recent_comments, setRecentComments] = useState();
  const [blogCategoryList, setBlogCategoryList] = useState();
  const [blogSearch, setSearchValue] = useState({
    search_input:"",
    auth_token:""
  });
  const [CommentId,setCommentId] = useState()
  const regex = /(<([^>]+)>)/ig;
  

  useEffect(() => {
    Category_Details();
    recentPost();
    blog_comment_list();
    recentComments();
    categoryList();
    BlogList();
  }, []);

  useEffect(() => {
    Category_Details();
    categoryList();
    BlogList();
  }, [id]);

  const Category_Details = async () => {
    let auth_token = MD5Hash('');
    let data = {
      "auth_token": auth_token,
      "id":id,
    }
    var res = await postRequest(CATEGORY_DETAILS, data);
    
    if (res.success) {
      setLoader(false)
      setCategoryDetails(res?.parameters[0]);
    }
  }

  const BlogList = async () => {
    let auth_token = MD5Hash('');
    let data = {
      "category_id": id,
      "auth_token":auth_token,
    }
    var res = await postRequest(BLOG_CATEGORY_LISTS, data);
    
    if (res) {
      setLoader(false)
      setBlogsList(res);
    }
  }
  
  function gotoTop(){
    window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
  }


  const recentPost = async () =>{
    let auth_token = MD5Hash('');
    let data = {
      "auth_token": auth_token,
    }
    var res = await postRequest(BLOG_RECENT_POST, data);
    if (res.success) {
      setRecentBlogs({
        ...recent_blogs,
        recent:res.parameters
      })
      
    }
  }

  const recentComments = async () =>{
    let auth_token = MD5Hash('');
    let data = {
      "auth_token": auth_token,
    }
    var res = await postRequest(BLOG_RECENT_COMMENTS, data);
    if (res.success) {
      setRecentComments(res?.parameters) 
      console.log("response id" , res)
      

    }
  }

  const categoryList = async () =>{
    let auth_token = MD5Hash('');
    let data = {
      "category_id": id,
      "auth_token": auth_token,
    }
    var res = await postRequest(BLOG_CATEGORY_LIST, data);
    if (res.success) {
      setBlogCategoryList(res?.parameters) 
    }
  }


  
 
  const blog_comment_list = async () => {
    let auth_token = MD5Hash(id);
    let data = {
      "blog_id":id,
      "auth_token": auth_token,
    }
    var res = await postRequest(BLOG_COMMENT_LIST, data);
    if (res.success) {
      setBlogCommentList(res?.parameters);
    }
  }

  const blog_search = async (e) => {
    blogSearch.auth_token = MD5Hash("");
    var res = await postRequest(BLOG_SEARCH, blogSearch);
    if (res.success) {
      // setBlogCommentList(res?.parameters);
    }
  }
     
  // console.log(blogs_list, 'sadsadsaddssad')
  return (
    <>{loader == true? <div  className='sloader'><img src={spinner}></img></div>:
    
    <div className="full-detail">
      <Helmet>
                <title>{(categoryDetails?.category_name ?categoryDetails?.category_name:"" ) + ` Archives Digidzn`}  </title>
                <meta name="description" content="Worried about growth hacking marketing strategies for Campaigns? Be it Facebook Ads or Google Ads, we are pro in handling and marketing digital campaigns. We not only create campaigns for you but also track campaigns for you."/>
            </Helmet>

      <div className="explore-container">
        <div>
          <Container>
            <Row>
              <Col className='mt-10'>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col md={8} className='mt-5'>
              
            <h1 class="cat-name">{categoryDetails.category_name}</h1>
                  

                {/* <div className="">
                <div>{ReactHtmlParser(categoryDetails?.description)}</div>
                <Link className="read-more-txt" to={`/Blog-view/${categoryDetails.id}`} >Read More</Link>
                </div> */}

                  {/* </Form> */}

                  <div className="">
                      {
                        blogs_list?.parameters!= null ? (
                          blogs_list?.parameters.data?.map((val, i)=>{
                            return(
                              <>
                              <img className='blog-imgg' src={BASE_IMAGE_URL+val?.blog_image_url}/>
                              <h4 className='bcat-name'>{val?.blog_name}</h4>
                              <div className="cmd-txt" id={i}><span><Link 
                              to={`/Blog-view/${val?.blog_id}`}>Leave a Comment </Link></span> / <span><Link onClick={gotoTop()} to={`/category-details/${id}`}>{val?.blogCategories[0].category_name}</Link></span> / <span>{'By ' + val?.master_name}</span></div>
                              <div className="img-size1">{ReactHtmlParser(val?.description)}</div>
                              <Link className="read-more-txt" to={`/Blog-view/${val?.blog_id}`} >Read More</Link>
                              </>
                            )
                          })
                        ):(
                          <div className='blog_error_msg'>{blogs_list.message}</div>
                        )
                        
                      }
                  </div>
              </Col>
              <Col md={1}></Col>
              <Col md={3} className='mt-5'>
                <div>
                  <input type="text" onChange={(e)=>{
                    setSearchValue({
                      ...blogSearch,
                      search_input:e.target.value
                    })
                    blog_search(e)
                  }} placeholder="Search" className="search-ip"  /> 
                  
                  <Link className="read-more-txt" to={`/Blog-search-view/${blogSearch.search_input}`}  ><FontAwesomeIcon icon={faSearch} className="fa-seacrh-icon" size="lg" color="grey" /> 
                 </Link>
                </div>
                <div className='recent-div'>
                  <div className='recent-post-div'>Recent Posts</div>
                  {
                    recent_blogs?.recent?.map((item)=>{
                      return(
                        // <a className='txt-size'>{item?.blog_name}</a>
                        <p><a className="txt-size" href={`/Blog-view/${item.blog_id}`} >{item?.blog_name}</a></p>
                      )
                    })
                  }
                </div>

                {/* <div className='recent-div'>
                  <div className='recent-post-div'>Recent Comments</div>
                  {
                    recent_comments?.map((item)=>{
                      return(
                        <p><a className='txt-size'>{item?.comments}</a></p>
                      )
                    })
                  }
                </div> */}

                <div className='recent-div'>
                  <div className='recent-post-div'>Categories</div>
                  {
                    blogCategoryList?.map((item)=>{
                      return(
                        // <p><a className='txt-size'>{item?.category_name}</a></p>
                        <p><a className="txt-size" href={`/category-details/${item.id}`} >{item?.category_name}</a></p>
                      )
                    })
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        

      </div>
      <Footer />
    </div>}</>
 )
}

export default BlogView




