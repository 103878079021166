import React from 'react'
import Caro1 from '../asset/img/1-6.webp'
import Caro2 from '../asset/img/2-5.webp'
import Caro3 from '../asset/img/3-4.jpg'
import Caro4 from '../asset/img/4-3.webp'
import { Row, Col, Container, Figure, Card } from 'react-bootstrap';
import Caro5 from '../asset/img/5-2.webp'
import Caro6 from '../asset/img/6-1.jpg'
import './carousel1.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const carousel1 = () => {
    
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoPlay: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const client = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
  return (
    <div>
        <div className="section3-carousel">
                <Container>
                    <Row>
                        <Col className="text-center sec-2-carousel mt-5">
                            <h2>
                                Result Oriented Branding & Digital Transformation
                            </h2>

                            <Slider {...settings}>
                                <div>
                                    <img
                                        className="d-block img-icon"
                                        src={Caro1}
                                        alt="Caro1"
                                    />

                                </div>
                                <div>
                                    <img
                                        className="d-block img-icon"
                                        src={Caro2}
                                        alt="Caro2"
                                    />
                                </div>
                                <div>
                                    <img
                                        className="d-block img-icon"
                                        src={Caro3}
                                        alt="Caro3"
                                    />
                                </div>
                                <div>
                                    <img
                                        className="d-block img-icon"
                                        src={Caro4}
                                        alt="Caro4"
                                    />
                                </div>
                                <div>
                                    <img
                                        className="d-block img-icon"
                                        src={Caro5}
                                        alt="Caro5"
                                    />
                                </div>
                                <div>
                                    <img
                                        className="d-block img-icon"
                                        src={Caro6}
                                        alt="Caro6"
                                    />
                                </div>

                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div>
    </div>
  )
}

export default carousel1