import React from 'react'
import { Row, Col, Container, Figure, Button, Modal } from 'react-bootstrap';
import Franimg from '../asset/img/banner-fran.png'
import Sec1 from '../asset/img/logo-1-1.png'
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom'
import Sec2 from '../asset/img/sec3.png'
import Sec3 from '../asset/img/logo2-1.png'
import Digital1 from '../asset/img/digital1.png'
import Testimonial from './testimonial';
import * as $ from 'jquery';
import { Redirect } from 'react-router-dom'
import emailjs from 'emailjs-com';
import { useEffect, useState } from 'react';
import { FaUserClock, FaStar, FaUsers, FaUsersCog, FaProjectDiagram, FaMoneyCheck, FaRegChartBar, FaUserAlt, FaLongArrowAltRight, FaDatabase, FaBusinessTime, FaNetworkWired } from 'react-icons/fa'
const Franchise = () => {
    useEffect(() => {
        $(".accordion-content").css("display", "none");
        $(".accordion-title").click(function () {
            $(".accordion-title").not(this).removeClass("open");
            $(".accordion-title").not(this).next().slideUp(300);
            $(this).toggleClass("open");
            $(this).next().slideToggle(300);
        });
        
       
       
    }, [])
   
    
    const [show, setShow] = useState(true); 
    const handleShow = () => setShow(true);
    

    const initialValues = { name: null, email: null, phonenumber: null, message: null, recaptcha: null };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isVerified, setVerified] = useState(null);

    const handleClose = ()=>{
        setFormErrors({name:null})
        setShow(false);
        setFormValues(initialValues);
    }
    const handleChange = (e) => {

        const { name, value } = e.target;
        // console.log("data",{name:value})
        setFormValues({ ...formValues, [name]: value });

    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    // if (this.state.verified) {
    //     fetch()
    //     .then(this.handleSuccess)
    //     .catch(error => alert(error))
    //     .preventDefault()
    // }
    // else {this.setState({reply: "Please verify the ReCaptcha."})}
    React.useEffect(() => {
        // register({ name: "spam" }, { required: "prove you are no bot plz!" });
    }, []);

    const handleRecaptcha = value => {
        console.log("Captcha value:", value);
        setVerified(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        if(Object.keys(validate(formValues)).length === 0){
            
            // console.log("target value",e.target,"form value data:",formValues)
            emailjs.sendForm('service_nhkno7v', 'template_89px82c', e.target , 'ukV0_dkxOVar5bwnV')
                .then(res =>{
                    console.log("res data",res)
                })
                .catch( err => {
                    console.log( "error data",err)
                })
        }
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {

            // console.log(formValues);
        }
    }, [formErrors])

    const validate = (values) => {
        const errors = {};
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const phone = /^[6-9]\d{9}$/;

        if (!values.name) {
            errors.name = "please enter your name";
        }
        if (!values.email) {
            errors.email = "please enter your email address";
        } else if (!regex.test(values.email)) {
            errors.email = "this is not a valid email format";
        }

        if (!values.phonenumber) {
            errors.phonenumber = "please enter your phone number";
        }
        else if (!phone.test(values.phonenumber)) {
            errors.phonenumber = "please enter your valid phone number";
        }

        // if(values.recaptcha == ""){
        //     errors.recaptcha = "The Captcha field cannot be blank. Please enter a value";
        // }

        return errors;
    };
    

    return (
        <div>
            <div>
                <Container fluid>
                    <Row className='gx-0'>
                        <Col className='mt-1'>
                            <img
                                width="100%"
                                src={Franimg}
                                alt='franchise'
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* section-2-franchise-page */}

            <div>
                <Container>
                    <Row>
                        <Col className="digital-page-sec1-heading text-center mt-5">

                            <h1>WHY TO PARTNER WITH digiDZN</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container className='p-5'>
                    <Row>


                        <Col md={4} xs={4} className="icon-digi text-center">
                            <FaStar className='digital-icon-edit'></FaStar>
                            <Figure.Caption className="mt-4 figure-digi">
                                4.9 rating on google
                            </Figure.Caption>

                        </Col>
                        <Col md={4} xs={4} className="icon-digi text-center">
                            <FaUsers className='digital-icon-edit'></FaUsers>
                            <Figure.Caption className="mt-4 figure-digi"> 30+ Team members </Figure.Caption>
                        </Col>
                        <Col md={4} xs={4} className="icon-digi text-center">
                            <FaUsersCog className='digital-icon-edit'></FaUsersCog>
                            <Figure.Caption className="mt-4 figure-digi"> Proven Results</Figure.Caption>
                        </Col>

                    </Row>
                    <Row className='mt-5'>


                        <Col md={3} xs={3} className="icon-digi text-center">
                            <FaBusinessTime className='digital-icon-edit'></FaBusinessTime>
                            <Figure.Caption className="mt-4 figure-digi">
                                1000+ SMEs assisted
                            </Figure.Caption>

                        </Col>
                        <Col md={3} xs={3} className="icon-digi text-center">
                            <FaNetworkWired className='digital-icon-edit'></FaNetworkWired>
                            <Figure.Caption className="mt-4 figure-digi">1 million+ verified B2B database </Figure.Caption>
                        </Col>
                        <Col md={3} xs={3} className="icon-digi text-center">
                            <FaUserClock className='digital-icon-edit'></FaUserClock >
                            <Figure.Caption className="mt-4 figure-digi"> Managed Rs. 60.0 Mn+ worth of campaigns till now</Figure.Caption>
                        </Col>
                        <Col md={3} xs={3} className="icon-digi text-center">
                            <FaRegChartBar className='digital-icon-edit'></FaRegChartBar>
                            <Figure.Caption className="mt-4 figure-digi"> 2.5 cr Impressions generating Rs.1.5 cr sales per month</Figure.Caption>
                        </Col>

                    </Row>

                </Container>
            </div>
            <div>
                <Container>
                    <Row>
                        <Col className='text-center mt-2 mb-4'>
                        <Link to='contact-us'>   <button className='franchis-btn'>Speak to DigiDZN <FaLongArrowAltRight></FaLongArrowAltRight></button> </Link>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Testimonial />
            <div className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} className="digi-sec2-para" >
                            <h1 className='mt-2 p-3'>ABOUT digiDZN</h1>
                            <p className='mt-2 p-2'>
                                We are a team of experts, growth hackers and creative minds!<br />
                                Let’s unlock the rapid growth of your business with the help of proficient and creative people. We are hardworking yet finicky about timely delivery and results.
                            </p>
                            <p className='mt-2 p-2 ' style={{ paddingRight: "60px" }}>
                                Let’s join the hands to build the better digital marketing community for SMEs, start-ups & local businesses.
                            </p>

                            <Row>
                                <Col xs={4} md={4}>
                                    <img
                                        className="d-block img-icon"
                                        src={Sec1}
                                        alt="Sec1"
                                    />
                                </Col>
                                <Col xs={4} md={4}>
                                    <img
                                        className="d-block img-icon"
                                        src={Sec2}
                                        alt="Sec2"
                                    />
                                </Col>
                                <Col xs={4} md={4}>
                                    <img
                                        className="d-block img-icon"
                                        src={Sec3}
                                        alt="Sec3"
                                    />
                                </Col>
                                <div>
                <Container>
                    <Row>
                        <Col className='text-center mt-2 mb-5'>
                        <Link to='contact-us'>   <button className='franchis-btn'>Leave your Details <FaLongArrowAltRight></FaLongArrowAltRight></button></Link>
                        </Col>
                    </Row>
                </Container>
            </div>
                            </Row>
                        </Col>

                        <Col md={6}>
                            <img
                                className="d-block img-icon text-center"

                                src={Digital1}
                                alt="Icon7"
                            />
                        </Col>
                    </Row>
                </Container>

            </div>

            <div className='gradiant'>
                <Container>
                    <Row>
                        <Col className='sec3-digi text-center'>
                            <h1>Partner With <br />Digital Marketing Agency</h1>
                            <p>&<br />BE YOUR OWN BOSS</p>

                            <Link to='contact-us'>    <button className='register-btn-digi text-center mt-3'>Let's Connect<FaLongArrowAltRight></FaLongArrowAltRight></button></Link>


                        </Col>
                    </Row>
                </Container>
            </div>

            {/* <div>
                <Container>
                    <Row>
                        <Col className="text-center heading-full-growth">
                            <h1>
                                WHAT CAN YOU EXPECT?
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div> */}
            {/* <div className="full-growth-bg">
                <Container className="mt-2 ">
                    <Row xs="3">
                        <Col md={6} xs={12}>
                            <div className="full-growthcontent">
                                <h6 className='text-center'>
                                    Digital Marketing
                                </h6>
                                <p>
                                    Do you want to execute modern yet effective SEO to power your business strategy? Let us help you to deliver what matters for your industry!
                                </p>
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            <Link to='/seo' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        Full Back Up Support
                                    </h6>
                                    <p>
                                        Finding new ways to interact and engage with the audience of your brand? Still not sure how to execute it? Let us assist you with befitting strategy and approach.
                                        <br />
                                        <br />
                                        Figuring out ways to generate sales on your e-commerce platforms? Well, we are here to expand your e-commerce business following all the algorithms and guidelines.
                                    </p>
                                </div>
                            </Link>
                        </Col>





                    </Row>
                    <Row>
                        <Col md={4} xs={12}>
                            <Link to='/social-media' className="text-none-underline">
                                <div className="full-growthcontent mt-5">
                                    <h6>
                                        Content Strategy & Writing
                                    </h6>
                                    <p>
                                        Dedicated resource trained on Digital Marketing & SEO
                                    </p>
                                </div>
                            </Link>
                        </Col>


                        <Col md={4} xs={12}>
                            <Link to='/campaigns' className="text-none-underline">
                                <div className="full-growthcontent mt-5">
                                    <h6>
                                        Content Strategy & Writing
                                    </h6>
                                    <p>
                                        Do you know what is essential to get quality leads? A good campaign strategy! Let us plan some terrific campaign strategies to make your brand go viral.
                                    </p>
                                </div>
                            </Link>
                        </Col>
                        <Col md={4} xs={12}>
                            <Link to='/campaigns' className="text-none-underline">
                                <div className="full-growthcontent mt-5">
                                    <h6>
                                        Social Media Marketing
                                    </h6>
                                    <p>

                                    </p>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center mt-5 mb-5">
                            <Link to='contact-us'> <button className="growth-button">Speak To digidzn</button> </Link>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                </Container>

            </div> */}
            <div>
                <Container>
                    <Row>
                        <Col className="digital-page-sec1-heading  mt-5 mb-5">
                            <h1 className='text-center'>FAQ</h1>
                            <div id="accordion" class="accordion-container">
                                <h4 class="accordion-title">1.What’s in it for me as your franchise owner?</h4>
                                <div class="accordion-content">
                                    <p className='mt-3 mb-3 p-faq'>
                                        <ul>
                                            <li>
                                                This program allows our partner to be a part of this fast-growing industry. While the partner is responsible for effective follow ups on the leads, and customer interactions – we provide complete operations support of the client’s work.
                                            </li>
                                            <li>
                                                In short, just focus on client acquisition while digiDZN team will ensure outstanding digital marketing results for the client. Our centralised team will handle all the operations related to the customer account for Digital Marketing.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <h4 class="accordion-title">2.Who can become a franchise partner?</h4>
                                <div class="accordion-content">
                                    <p>
                                        If you are any of the following then you can be a Franchise partner with us:
                                        <ul>
                                            <li>

                                                <li>Network Specialists</li>
                                                <li>Mompreneurs</li>
                                                <li>Retired Professionals</li>
                                                <li>HR Professionals</li>
                                                <li>Graphics Designer</li>
                                                <li>Freelancers</li>
                                                <li>Business development professionals</li>
                                                <li>Or anyone with a business network of 50+</li>

                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <h4 class="accordion-title">3.What will digiDZN do?</h4>
                                <div class="accordion-content">
                                    <ul>
                                        <li>We will book up a place in a co-working office for the franchise</li>
                                        <li>Create it as Sales Office in records</li>
                                        <li>Doing necessary lead generation for follow ups by Franchise Sales Partner</li>
                                        <li>Sharing of income, expenses, and revenue report by the first week of every month</li>
                                    </ul>
                                </div>
                                <h4 class="accordion-title">4.Why DigiDZN?</h4>
                                <div class="accordion-content">
                                    <p>
                                        The reasons why anyone should partner with us:
                                        <ul>
                                            <li >

                                                <li>We have worked and are working for Big Brands like Matrix Cellular, TOI Ads, and multiple international clients</li>
                                                <li>No HR stress- Full delivery work to be done by digiDZN team</li>
                                                <li>Quality &amp; Filtered leads to be provided by digiDZN to you for closures</li>
                                                <li>Very low investment compared to other options</li>
                                                <li>No limit on earnings</li>

                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <h4 class="accordion-title">5.What are the investment and returns of this franchise program?</h4>
                                <div class="accordion-content">
                                    <p>
                                        <ul>
                                            <li>There is a one-time franchise fee of Rs. 50,000 and Rs. 1.5 lakh as the security deposit, the partner can have up to 40% share in revenue. Please get in touch with us for more details.</li>
                                        </ul>
                                    </p>
                                </div>
                                <h4 class="accordion-title">6.What will be the process after I sign up?</h4>
                                <div class="accordion-content">
                                    <p>
                                        After you are signed up for the program, you are expected to:
                                        <ul>
                                            <li >

                                                <li>Get into a formal agreement with digiDZN after payment of necessary franchise fees/deposit</li>
                                                <li>Follow Up on the leads generated by digiDZN for your location</li>
                                                <li>Onboard the client</li>
                                                <li>Collect payments from the clients for digiDZN.</li>

                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <h4 class="accordion-title">7.Is there any guarantee of results?</h4>
                                <div class="accordion-content">
                                    <p>
                                        <ul>
                                            <li>You are reading this because of our digital marketing efforts. One out of twenty of you will convert as franchise sales partner. With time digital marketing efforts produce results that your sales can’t handle</li>
                                        </ul>
                                    </p>
                                </div>
                                <h4 class="accordion-title">8.Is your work in line with the latest updates of Google algorithms?</h4>
                                <div class="accordion-content">
                                    <p>
                                        <ul>
                                            <li>Absolutely, our team spends a lot of time researching the latest developments in the SEO world; hence our work is always abreast with the latest updates of Google algorithms.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='enquiry-now'>
                <button className='enquiry-now-btn' id='rotate' onClick={handleShow}>Enquire Now</button>
            </div>


            <Modal show={show} onHide={handleClose}   id="simpleModal" centered className="modal popup-enquiry" closeButton>
                <Modal.Header closeButton>

                </Modal.Header>
                <div className="popup-text " closeButton>
                    <h4 className="text-center">ENQUIRE NOW</h4>
                    <form className="contactform fran-contact" onSubmit={handleSubmit}>
                        <input type='text'

                            name="name"
                            value={formValues.name}
                            onChange={handleChange}
                            placeholder="Name"
                            className='border-fra'
                        />
                        <p className="mb-4">{formErrors.name}</p>
                        <input type="email"
                            className='border-fra'
                            name="email"
                            value={formValues.email}
                            onChange={handleChange}
                            placeholder="Email"
                        />
                        <p className="mb-4">{formErrors.email}</p>
                        <input type="tel"
                            className='border-fra'
                            onKeyPress="if(this.value.length==10) return false;"
                            name="phonenumber"
                            value={formValues.phonenumber}
                            onChange={handleChange}
                            placeholder="Phone number"
                        />
                        <p className="mb-4">{formErrors.phonenumber}</p>



                        {/* <ReCAPTCHA
                                    className="g-recaptcha"
                                    sitekey="6LdqGGQaAAAAABS5Vtjm1NTJ9HdMK8rKjR96V0gE"
                                    onChange={this.onChange}
                                    theme="dark"
                                />
                                { this.state.verified ? <p id="error" className="error">{this.state.reply}</p> : null }
                                { this.state.showSuccess ? <p id="success" className="success">Thank you, we will be in touch asap.</p> : null } */}
                        {/* <p className="mb-4">{formErrors.recaptcha}</p>    */}
                        <button className="franchise-submit-btn">Send</button>
                    </form>
                    {Object.keys(formErrors).length === 0 && isSubmit ? (<Redirect to='/thank-you-page' />) : (<div></div>)}

                </div>
            </Modal>


            <Footer />

        </div>
    )
}

export default Franchise
