import React from 'react'




const Animation = () => {
    


    var TxtRotate = function(el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 1) || 1000;
        this.txt = '';
        this.tick();
        this.isDeleting = false;
      };
      
      TxtRotate.prototype.tick = function() {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];
      
        if (this.isDeleting) {
          this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
          this.txt = fullTxt.substring(0, this.txt.length + 1);
        }
      
        this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';
      
        var that = this;
        var delta = 100 - Math.random() * 100;
      
        if (this.isDeleting) { delta /= 2; }
      
        if (!this.isDeleting && this.txt === fullTxt) {
          delta = this.period;
          this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
          this.isDeleting = false;
          this.loopNum++;
          delta = 500;
        }
      
        setTimeout(function() {
          that.tick();
        }, delta);
      };
      
      window.onload = function() {
        var elements = document.getElementsByClassName('txt-rotate');
      ;
        console.log(elements);
        for (var i=0; i<elements.length; i++) {
          
          var toRotate = elements[0].getAttribute('data-rotate');
        console.log(toRotate);
          var list = toRotate.split(',');
          // console.log(list);
          var period = elements[i].getAttribute('data-period');
          if (list) {
              // console.log(toRotate);
            new TxtRotate(elements[i], list, period);
          }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".txt-rotate > .wrap {  }";
        document.body.appendChild(css);
      };

    return (
        <div>
            <span
            
                            class="txt-rotate title"
                            data-period="1000"
                            data-rotate='Conversion, Userbase, Leads, Sales, Brands'>

                        </span>
        </div>
    )
}

export default Animation
