import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row,Col,Container,Card} from 'react-bootstrap';
import EMC1 from '../asset/img/emc-1.png'
import EMC2 from '../asset/img/emc-2.png'
import EMC3 from '../asset/img/emc-3.png'

import Result1 from '../asset/img/sec-10.jpg'
import Result2 from '../asset/img/sec-11.jpg'
import Blogimg1 from '../asset/img/blog-img1.png'
import Blogimg2 from '../asset/img/blog-img2.jpg'
import Blogimg3 from '../asset/img/blog-img3.png'
import Footer from '../Components/Footer';
import Testimonial from './testimonial';
import {Link} from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
function socialmedia() {
    return (
        <div>
            <Helmet>
                <title>Digital Ad Agency | Top digital advertising company | digiDZN</title>
                <meta name="description" content="Worried about growth hacking marketing strategies for Campaigns? Be it Facebook Ads or Google Ads, we are pro in handling and marketing digital campaigns. We not only create campaigns for you but also track campaigns for you."/>
            </Helmet>
            {/* <div className="social-banner">
                <Container>
                    <Row>
                        <Col className="social-heading mt-5"> 
                            <h2>
                            Strategy SEO to Power your Business
                            </h2>
                            <h6 className="mt-4">Search Engine Optimization is an evolving science to improve your page rank. <br/>
                            SEO is a process of increasing the visibility of the website in a web search engine organically.</h6>
                        
                        </Col>
                        
                    </Row>
                </Container>
            </div> */}
            {/* section 2 social media */}
            <div >
                <div className='space-1'></div>
                <Container>
                    <Row >
                        <Col md={6} xs={12} >  
                                            <img
                                                    className="d-block social-media mt-10 "
                                                    width="125"
                                                    src={EMC1}
                                                    alt="Social2"
                                                    />
                        </Col>
                        <Col md={6} xs={12} className="social-section mt-10">
                            <h1>
                            Online Store
                            </h1>
                            <p>
                                
                            An online store to buy and sell products. We systematically optimize your e-commerce store, integrating the right approach and strategy to help you grow. Worried about if we can handle all your e-commerce platforms? Well, worry not! We are professionals in handling and developing our own custom e-commerce, which includes cataloguing of products, warehousing, and much more!

                            </p>
                            <Link to="/contact-us"> <button className="growth-button mt-5">FIND OUT MORE</button></Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section2 */}
            <div className="mt-10 desktop-tracking">
                <Container>
                    <Row>
                        <Col md={6} xs={12} className="social-section">
                            <h1>
                            Marketplace Management
                            </h1>
                            <p>
                                
                            We offer a complete set of marketplace management services. What all it includes? It's simple from vendor registration, cataloguing, listing, inventory management, order management to promotions, handling reviews, Amazon A+ listing and payment reconciliation as well. 
<br />
It's time to reach millions of buyers online who might be interested in your products!

                            </p>
                            <Link to="/contact-us"> <button className="growth-button mt-5">FIND OUT MORE</button></Link>
                        </Col>
                        <Col md={6} xs={12}>
                                                    <img
                                                    className="d-block social-media "
                                                    width="125"
                                                    src={EMC2}
                                                    alt="Social3"
                                                    />
                            
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* mobile responsive */}

            <div className="mt-10 mob-res-tracking">
                <Container>
                    <Row>
                        <Col md={6} xs={12} className="social-section">
                            
                        <img
                                                    className="d-block social-media "
                                                    width="125"
                                                    src={EMC2}
                                                    alt="Social3"
                                                    />
                           
                        </Col>
                        <Col md={6} xs={12}>
                        <h1>
                        Marketplace Management
                            </h1>
                            <p>
                                
                            We offer a complete set of marketplace management services. What all it includes? It's simple from vendor registration, cataloguing, listing, inventory management, order management to promotions, handling reviews, Amazon A+ listing and payment reconciliation as well. 

It's time to reach millions of buyers online who might be interested in your products!

                            </p>
                            <Link to="/contact-us"> <button className="growth-button mt-5">FIND OUT MORE</button></Link>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* section3 */}
            <div className="mt-5 mb-5">
                <Container>
                    <Row>
                        <Col md={6} xs={12}>
                                            <img
                                                    className="d-block social-media "
                                                    width="125"
                                                    src={EMC3}
                                                    alt="Social1"
                                                    />
                        </Col>
                        <Col md={6} xs={12} className="social-section">
                            <h1>
                            Customer Relationship Management (CRM)
                            </h1>
                            <p>
                                
                            To stand out in this competitive market, online businesses have to drive more brand recall and revenue-generating strategies. The primary aspect of any business be it in product selling or services is providing a great customer experience. And, CRM is a great strategy to integrate into e-commerce. From tracking, managing customer data to revert to their feedback, we can handle it all!

                            </p>
                            <Link to="/contact-us"> <button className="growth-button mt-5">FIND OUT MORE</button></Link>
                        </Col>
                    </Row>
                </Container>
            </div>  
            {/* testimonial           */}
            <Testimonial />

            {/* section4 */}

            <div className="bg-result mt-1 pb-5">
            <Container>
                <Row>
                    <Col className="text-center mt-1 heading-full-growth">
                    <h1>
                    Consistently Delivering Results
                    </h1>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col className="mt-5 ">
                        <div className="bgcolor-result"> 
                                    <img
                                    className="d-block img-result border-result"
                                    src={Result1}
                                    alt="Sec-10"
                                    />
                        </div>
                    </Col>
                    <Col className="mt-5">
                        <div className="bgcolor-result">
                                   <img
                                    className="d-block border-result img-result"
                                    src={Result2}
                                    alt="Sec-10"
                                    />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center mt-5">
                    <Link to="/results"><button className="growth-button">Read more</button></Link>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col className="text-center  heading-full-growth">
                    <h1>
                    Growth Marketing Bites
                    </h1>
                    </Col>
                </Row>
            </Container>
        </div>

        <div>
            <Container>
                <Row>
                    <Col md={4}> 
                    <Card className="border-card" style={{ width: "100%" }}>
                        <Card.Body>
                        <Card.Img variant="top" class="blog-img" src={Blogimg1} />
                        <Card.Title className="mt-2 blog-title">HOW TO USE FACEBOOK VIDEO ADS TO GROW YOUR BUSINESS</Card.Title>
                        <Card.Subtitle className="mb-2 mt-2 blog-sub">
                        September 24, 2021
                        </Card.Subtitle>
                        <Card.Text className="mb-2 mt-2 blog-text">
                        We’re presuming you already know that videos are the way
                        to go and that you’ll need to run Facebook advertising. And this implies that you’re
                        </Card.Text>
                        <Card.Link className="blog-link" href="#"> Read More</Card.Link>
                        </Card.Body>
                    </Card>
                    </Col>
                <Col md={4}>
                <Card className="border-card" style={{ width: "100%" }}>
                    <Card.Body>
                    <Card.Img variant="top" class="blog-img" src={Blogimg2} />
                    <Card.Title className="mt-2 blog-title">Are Facebook ads worth it? (Yes, here’s why)</Card.Title>
                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                    September 20, 2021
                    </Card.Subtitle>
                    <Card.Text className="mb-2 mt-2 blog-text">
                    Facebook seems to have run its course. With people moving towards 
                    newer social media platforms and with all the false media, it may look like
                    </Card.Text>
                    <Card.Link className="blog-link" href="#"> Read More</Card.Link>
                    </Card.Body>
                </Card>
                </Col>
                <Col md={4}>
                <Card className="border-card" style={{ width: "100%" }}>
                    <Card.Body>
                    <Card.Img variant="top" class="blog-img" src={Blogimg3} />
                    <Card.Title className="mt-2 blog-title">Best Apps for Social Media Marketing 2021</Card.Title>
                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                    August 28, 2021
                    </Card.Subtitle>
                    <Card.Text className="mb-2 mt-2 blog-text">
                    Social media is necessary for both marketers and consumers, 
                    whether you like it or not. People quickly realized how crucial it was to maintain a
                    </Card.Text>
                    <Card.Link className="blog-link" href="#"> Read More</Card.Link>
                    </Card.Body>
                </Card>
                </Col>
                </Row>
            </Container>
        </div>
        
        {/* section6-ready to kickoff  http://www.w3.org/2000/svg*/}
        <div class="custom-shape-divider-top-1635236144 mt-5">
            <svg data-name="Layer 1" xmlns="" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
            </svg>
       </div>
       <div className="ready-kick-bg">
    
            <Container >
                <Row>
                    <Col className="text-center sec-6-head">
                    <h1>Ready To Kick Off Your Growth Journey</h1>
                    <Link to="/contact-us"> <button className="ready-kick-btn mt-5">SPEAK TO DIGIDZN</button></Link>
                    </Col>

                </Row>
            </Container>
        </div>
    <Footer/>

        </div>
    )
}

export default socialmedia
