 import React from 'react'
 import {Row,Col,Container} from 'react-bootstrap';
 import Footer from '../Components/Footer';
import Card from './card'
 import Client1 from '../asset/img/Amardeep.webp'
 import Career from '../asset/img/career.jpg'
 import Testimonial from './testimonial';
 import logo1 from '../asset/img/Surface.png'
 import logo2 from '../asset/img/Maven.png'
 import logo3 from '../asset/img/Gracini.png'
 import logo4 from '../asset/img/Lavanika.png'
 import logo5 from '../asset/img/Deco.png'
 import logo6 from '../asset/img/aakav.jpg'
 import logo7 from '../asset/img/ABYB.png'
 import logo8 from '../asset/img/AR.png'
 import logo9 from '../asset/img/bhakti.png'
 import logo10 from '../asset/img/credit.png'
 import logo11 from '../asset/img/Famphy.png'
 import logo12 from '../asset/img/GS.png'
 import logo13 from '../asset/img/Glocal.png'
 import logo14 from '../asset/img/imperf.jpeg'
 import logo15 from '../asset/img/le-amanah.png'
 import logo16 from '../asset/img/OMORFEE.png'
 import {Link} from 'react-router-dom'
 import { Helmet } from 'react-helmet-async';
 const about = () => {
     
     return (
         <div>
            <Helmet>
                <title>Creative Agency New Delhi | Digital Marketing Agency | digiDZN</title>
                <meta name="description" content="digiDZN is a full-scale DIgital Marketing Agency based out of New Delhi, India. We mix our year of experience and knowledge to create opportunites for our client."/>
            </Helmet>
            <div className="about-sec-bg">
                <Container>
                    <Row>
                        <Col className="mt-10 mt-mob-11 about-sec1-heading">
                        <h1 >We are a team of experts, growth hackers and creative minds!</h1>
                        <p>Let’s unlock the rapid growth of your business with the help of proficient and creative people. 
                            We are hardworking yet finicky about timely delivery and results. Don’t know about the algorithms
                             of Facebook, Instagram, Twitter, Linked or YouTube? Worried about growth hacking marketing
                              strategies? Leave it on us!</p>
                        </Col>
                    </Row>
                </Container>
            
            </div>
            <div className="about-sec-bg">
                <Container>
                    <Row>
                        <Col className="mt-10 mt-mob-10 about-sec2-heading text-center">
                        <h2 >Meet Your Extended Growth Team</h2>

                        
                        </Col>
                    </Row>
                </Container>    
                <div className="mt-5">
                    <Card />
                </div>
            </div>

            <div>
                <Container>
                    <Row>
                        <Col md={6} className="p-5 about-img-sec2">
                        <img
                                                    className="d-block "
                                                    width="60%"
                                                    height=""
                                                    src={Career}
                                                    alt="Client1"
                                                    />
                        </Col>
                        <Col md={6} className="p-5 about-heading-sec2">
                            <h1>Interested in joining the digiDZN squad?<br />We’ve got a place for<br /> you.</h1>
                           <Link to="/contact-us"> <button className="mt-5 about-sec2-btn">Read more</button></Link>
                        </Col>
                    </Row>
                </Container>
            </div>   
            <Testimonial />
            
           
            {/* <Container>
                <Row>
                    <Col md={4} >
                        <div className='about-sec-img'>
                    <img
                                                    className="d-block testimonial-img center"
                                                    width="125"
                                                    src={Client1}
                                                    alt="Client1"
                                                    />
                                <div className="overlay">
                                    <div className="text">
                                        <h1>Hello World</h1>
                                        <p>Hover over the image to see the effect.</p>
                                    </div>
                                </div>
                                </div>
                        
                    </Col>
                    
                    <Col md={4}>

                    </Col>
                    
                    <Col md={4}>

                    </Col>
                </Row>
            </Container> */}
        <div className="client-logo">
            <Container>
                <Row>
                    <Col className="text-center client-logo-heading mt-5">
                   <h1> Building Brands With These Amazing Platforms</h1>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="client-logo-des">
                <Row className="client-logo-img mt-5">
                    
              
                    <Col xs={4} md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo1}
                     alt="Client1"
                     />       
                    </Col>
                    
                    <Col xs={4} md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo2}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col xs={4} md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo3}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col xs={2} md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo4}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col xs={2} md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo5}
                     alt="Client1"
                     /> 
                    </Col>
                    
                  
                </Row>
                <Row className="client-logo-img mt-5">
                    
              
                    <Col md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo6}
                     alt="Client1"
                     />       
                    </Col>
                    
                    <Col md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo7}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo8}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo9}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo10}
                     alt="Client1"
                     /> 
                    </Col>
                    
                  
                </Row>
                <Row className="client-logo-img mt-5">
                    
              
                    <Col md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo11}
                     alt="Client1"
                     />       
                    </Col>
                    
                    <Col md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo12}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo13}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col md={2}>
                    <img
                     className="d-block "
                      width="90%"
                     height=""
                     src={logo14}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo15}
                     alt="Client1"
                     /> 
                    </Col>
                    
                  
                </Row>

                <Row className="client-logo-img mt-2">
                    
              
                    <Col md={2}>
                    <img
                     className="d-block "
                      width="100%"
                     height=""
                     src={logo16}
                     alt="Client1"
                     />       
                    </Col>
                    
                    <Col md={2}>
                     
                    </Col>
                    <Col md={2}>
                    
                    </Col>
                    <Col md={2}>
                    
                    </Col>
                    <Col md={2}>
                    
                    </Col>
                    
                  
                </Row>
            </Container>


            {/* mobile responsive client logo */}

            <Container fluid className="client-logo-mob">
                <Row className="client-logo-img mt-5">
                    
              
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo1}
                     alt="Client1"
                     />       
                    </Col>
                    
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo2}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo3}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo4}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo5}
                     alt="Client1"
                     /> 
                    </Col>
                    
                  
                
              
                    
              
                    <Col xs={4}  md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo6}
                     alt="Client1"
                     />       
                    </Col>
                    
                    <Col xs={4}  md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo7}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col xs={4}  md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo8}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo9}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo10}
                     alt="Client1"
                     /> 
                    </Col>
                    
                  
                
                    
              
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo11}
                     alt="Client1"
                     />       
                    </Col>
                    
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo12}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo13}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="90%"
                     height=""
                     src={logo14}
                     alt="Client1"
                     /> 
                    </Col>
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo15}
                     alt="Client1"
                     /> 
                    </Col>
                    
                  
              
                    <Col xs={4} md={2}>
                    <img
                     className="d-block mt-5"
                      width="100%"
                     height=""
                     src={logo16}
                     alt="Client1"
                     />       
                    </Col>
                    
                    <Col xs={4} md={2}>
                     
                    </Col>
                    <Col xs={4} md={2}>
                    
                    </Col>
                    <Col xs={4} md={2}>
                    
                    </Col>
                    <Col xs={4} md={2}>
                    
                    </Col>
                    
                  
                </Row>
            </Container>
        </div>
        <div className="client-logo " style={{height:'200px'}}>
           
        </div>
        <div class="custom-shape-divider-top-1635236144 about-kick-off">
    <svg data-name="Layer 1" xmlns="" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
    </svg>
</div>
       <div className="ready-kick-bg">
    
    <Container >
        <Row>
            <Col className="text-center sec-6-head">
            <h1>Ready To Kick Off Your Growth Journey</h1>
            <Link to="/contact-us"><button className="ready-kick-btn mt-5">SPEAK TO DIGIDZN</button></Link>
            </Col>

        </Row>
    </Container>
</div>

            <Footer />
         </div>
     )
 }
 
 export default about
 