import React from "react";
import "./box.css";
// import { Card } from "react-bootstrap";
import { Row, Col, Container } from 'react-bootstrap';
import img1 from '../asset/img/Manish-Verma.jpg';
import img2 from '../asset/img/Sangeeta-Verma-1.jpg';
import img3 from '../asset/img/vishal.jpg';
import img4 from '../asset/img/gursahi.jpg';
import img5 from '../asset/img/SUNITA.png';
import img6 from '../asset/img/dviya.jpg'
import img7 from '../asset/img/Jatin.jpg'
import img8 from '../asset/img/worker3.jpeg'
import img9 from '../asset/img/Shivani.jpg'
import img10 from '../asset/img/Jasmeen.jpg'
import img11 from '../asset/img/worker6.jpg'
import img12 from '../asset/img/worker7.jpg'
import img13 from '../asset/img/worker8.jpeg'
import img14 from '../asset/img/worker9.jpeg'
import img15 from '../asset/img/neha.jpg'
import img16 from '../asset/img/Pallavi.jpeg'
import img17 from '../asset/img/Akash.jpeg'
import img18 from '../asset/img/worker15.jpeg'
import img19 from '../asset/img/Tanya.jpg'
import img20 from '../asset/img/Wadhwani.jpg'
// import img1 from '../asset/img/Manish-Verma.jpg';
const MoreDeets = () => {
  const image = require.context('../../src/asset/img', true);
  const cardInfo = [

    {
      image:
        img1,
      title: "MANISH VERMA",
      text: "Keen, logical, observant, and ever learner - Manish ensures that the team keeps its focus on broader vision while completing its daily tasks in a process-oriented way. With senior management experience of over 14 years with likes of ICICI, Reliance & Fullerton, Manish backs up the team fully to achieve growth.",
    },

    {
      image:
        img2,
      title: "SANGEETA VERMA",
      text: "What’s need to be done needs to be done. It’s better when a woman does it. Starting from scratch she has built a successful Digital Marketing agency in 6 years. She is very sound technically on various aspects of Digital Marketing",
    },
    {
      image:
        img3,
      title: "VISHAL RAJPUT",
      text: "The lone king of Google ads! Ever learner & always up to be a team player. When it comes to google ads, SEO, and all about website, I am always up to date. Bonus: Sarcasm is my superpower.",
    },
    {
      image:
        img4,
      title: "GURSAHIB SINGH",
      text: "Part Time Graphic Designer, Full Time Creator!",
    },
    {
      image:
        img5,
      title: "SUNITA TENIA",
      text: "She's always ready for work!!! As a digital marketing administrator, she is responsible for overseeing the digital presence and promotions of businesses, companies and different organizations.",
    },
    {
      image:
        img6,
      title: "DIVYA AGGARWAL",
      text: "Experienced Digital Marketer with a measurable track record of creating successful online strategic marketing campaigns, resulting in enhanced brand positioning and growth.",
    },
    {
      image:
        img7,
      title: "JATIN SHARMA",
      text: "Passionate learner with morality of work ethics and hard work.",
    },
    {
      image:
        img8,
      title: "BHAVYA PAMBRI",
      text: "She's Bhavya! She'll help you with Facebook & Instagram Ads. Looking forward to discussing Strategies, campaigns and Obviously, MIND-BLOWING Results.",
    },
    {
      image:
        img9,
      title: "SHIVANI MITTAL",
      text: "Goal oriented, highly motivated, leadership skills and believes in SMART working. I know how to handle pressure and deliver the best results.",
    },
    {
      image:
        img10,
      title: "JASMEEN KAUR",
      text: "I am here to put everything out on display! I post creatives, videos, and all the content on social media profiles of the clients. Without a proper management of the design and content buffer, there would be no posting on social media handles of the clients.",
    },
    {
      image:
        img11,
      title: "GURPREET KAUR",
      text: "To be the part of an organization where I can grow in term of knowledge skills and attitude. Aiming at constant upgradation both professionally as well as personally in my carrier part I love exploring new opportunities and working on different projects to constantly improve my skill set",
    },
    {
      image:
        img12,
      title: "ANURAG",
      text: "The only line that elaborate me I believe in evidence. I believe in observation, measurment, and reasoning",
    },
    {
      image:
        img13,
      title: "HEMLATA",
      text: "There's a lot that the eyes miss, but that's not the case with her. Efficiently saving costs & time for the company, she monitors the logistics & supports the eCommerce division of the company.",
    },
    {
      image:
        img14,
      title: "HEMA",
      text: "With 8+ years of experience in managing multi product, multi platform eCommerce sales, operations & logistics - I brings with her all the expertise to launch & grow a brand successfully through e-Commerce.",
    },
    {
      image:
        img15,
      title: "NEHA",
      text: "I am a BCA graduate and also done diploma in Multimedia & Animation. I am quite good in photo and video editing. Creative and keen learner. Believe in solving problem instead of cribbing about it. As an Optimist, I always believe tomorrow will be better than today.",
    },
    {
      image:
        img16,
      title: "PALLAVI ROHATGI GUPTA",
      text: "A creative head who is a writer in the day and a reader at the night.",
    },
    {
      image:
        img17,
      title: "AKASH RATHORE",
      text: "I am talented, ambitious and hardworking. Further, my creative mind provides solutions when working under pressure, critical deadlines and challenging situations.",
    },
    {
      image:
        img18,
      title: "VIDHI",
      text: "She is here to put everything out on display. She creates creatives,videos and all the contents for social media profiles of the clients.",
    },
    {
      image:
        img19,
      title: "TANYA GOEL",
      text: "As a content writer I like playing with words. I am responsible for styling and formatting consistency across all projects and communicating with other team members to create the best content possible. Other duties and responsibilities include, Working with customers to define their content needs.",
    },
    {
      image:
        img20,
      title: "TANYA WADHWANI",
      text: "Digital Marketing Associate, assisting the team with the SEO part, Blog writing, crafting and implementing advertisement and marketing plans present on social media and other digital platforms.",
    },
  ];

  const renderCard = (card, index) => {
    return (
      <div >
        <Container>
          <Row>
            <Col  >
              <div className='about-sec-img'>
                <img
                  className="d-block center"
                  width="100%"
                  height=""
                  src={card.image}
                  alt="Client1"
                />
                {/* <img src="" alt="Avatar" > */}
                <div className="overlay">
                  <div className="text">
                    <h1>{card.title}</h1>
                    <p>{card.text}</p>
                  </div>
                </div>
              </div>

            </Col>
          </Row>
        </Container>
        {/* // <Card style={{ width: "18rem" }} key={index} className="box">
      //   <Card.Img variant="top" src="holder.js/100px180" src={card.image} />
      //   <Card.Body>
      //     <Card.Title>{card.title}</Card.Title>
      //     <Card.Text>{card.text}</Card.Text>
      //   </Card.Body>
      // </Card> */}
      </div>
    );
  };

  return <div className="grid">{cardInfo.map(renderCard)}</div>;
};

export default MoreDeets;