import './Navbar.css'
import React , {useState, useEffect} from 'react'
import {NavLink,Link,BrowserRouter} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import {FaBars, FaTimes} from 'react-icons/fa';
import logo from '../asset/img/logo.png';
import logoBlue from '../asset/img/logoblue.png';
import { data } from 'jquery';
import { borderBottom } from '@mui/system';
import { useLocation } from "react-router-dom";

function Navbar() {



    const [icon , sectIcon] = useState(false)
    const handleClick = () => {
        sectIcon(!icon)
    }
    const closeSideDrawer = () => {
        sectIcon(false)
    }

    const [navColor, setnavColor] = useState("transparent");

    const [navbar, setNavbar] = useState(false)
    const [navlink, setLinks] = useState(false)
    const [blognavlink, setLinksblog] = useState(false)
    const [navbarLogo, setNavbarLogo] = useState(logo)

    const blogchangeColor = () => {
      console.log(window.scrollY)
      if (window.scrollY >= 60) {
        setLinksblog(true)
      } else {
        setLinksblog(false)
      }
    }
    useEffect(() => {
      blogchangeColor()
      // adding the event when scroll change background
      window.addEventListener("scroll", blogchangeColor)        
    })

    const changeLogo = () => {
        if (window.scrollY >= 60) {
          setNavbarLogo(logoBlue)
        } else {
          setNavbarLogo(logo)
        }
      }

      useEffect(() => {
        changeLogo()
        // adding the event when scroll change Logo
        window.addEventListener("scroll", changeLogo)
      })
        
      const changeBackground = () => {
        console.log(window.scrollY)
        if (window.scrollY >= 60) {
          setNavbar(true)
        } else {
          setNavbar(false)
        }
      }
    
    
      useEffect(() => {
        changeBackground()
        // adding the event when scroll change background
        window.addEventListener("scroll", changeBackground)
      })



      const changeColor = () => {
        console.log(window.scrollY)
        if (window.scrollY >= 60) {
          setLinks(true)
        } else {
          setLinks(false)
        }
      }
      useEffect(() => {
        changeColor()
        // adding the event when scroll change background
        window.addEventListener("scroll", changeColor)        
      })

      const location = useLocation();
      const { pathname } = location;
      const splitLocation = pathname.split("/");
      const activeURL = splitLocation[1];
      console.log("url",activeURL);

      const error = activeURL == "Blog" || activeURL == "Blog-view" ? 'main-nav-active' : ""
      var error1 = blognavlink ? "blog-black" : " blognavlink"
      const classes = `${error1} ${error}`

    return (
        <>
            <nav className={navbar ? "navbar active" : "navbar"} sticky="top">
            <BrowserRouter forceRefresh={true}>   <Link to = '/' className='nav-logo'
                onClick = {closeSideDrawer}> 
                <img
                className="d-block w-30 h-60"
                width="164"
                src={navbarLogo}
                alt="logo"
                />
              </Link></BrowserRouter>
                <div className='menu-icon'
                onClick = {handleClick}>
                    {
                        icon ? <FaTimes
                        className='fa-times'></FaTimes>:<FaBars className='fa-bars'></FaBars>
                    }
                </div>
                <ul  className={
                    icon ? 'nav-menu active' :
                    'nav-menu' 
                }>
                <li  >
                    <NavLink exact to ='/Franchise'
                    className={navlink ? "nav-links active" : "nav-links"}  activeClassName='main-nav-active'  onClick = {closeSideDrawer}>Franchise</NavLink>
                </li>
                <li>
                    <NavLink exact to ='/results'
                    className={navlink ? "nav-links active" : "nav-links"}   activeClassName='main-nav-active' onClick = {closeSideDrawer}>Results</NavLink>
                </li>
                <li>
                    <NavLink exact to ='/about'
                    className={navlink ? "nav-links active" : "nav-links"}  activeClassName='main-nav-active' onClick = {closeSideDrawer}>About</NavLink>
                </li>
                <li>
                    <NavLink exact to ='/services'
                    className={navlink ? "nav-links active" : "nav-links"}  activeClassName='main-nav-active' onClick = {closeSideDrawer}>Services</NavLink>
                </li>
                <li>
                 <NavLink  to ='/Blog'
                   id='blogctive' className={classes} onClick = {closeSideDrawer}>Blogs</NavLink>
                </li>
              
                <li>
                    <NavLink exact to ='/contact-us'
                    className={navlink ? "nav-links active" : "nav-links"}  activeClassName='main-nav-active' onClick = {closeSideDrawer}>Contact Us</NavLink>
                </li>    
                </ul>
            </nav>


            {/* <nav className='navbar mt-10' style={{backgroundColor:"white"}} sticky="top">
                <Link to = '/' className='nav-logo'
                onclick = {closeSideDrawer}> 
                <img
                className="d-block w-30 h-60"
                width="179"
                src={logoBlue}
                alt="logo"
                />
              </Link>
                <div className='menu-icon'
                onClick = {handleClick}>
                    {
                        icon ? <FaTimes
                        className='fa-times'></FaTimes>:<FaBars className='fa-bars'></FaBars>
                    }
                </div>
                <ul  className={
                    icon ? 'nav-menu active' :
                    'nav-menu' 
                }>
                <li  >
                    <NavLink exact to ='/digitalmarketinginternship'
                    className='nav-links'  activeClassName='main-nav-active'  >D3M Academy</NavLink>
                </li>
                <li>
                    <NavLink exact to ='/results'
                    className='nav-links' activeClassName='main-nav-active' >Results</NavLink>
                </li>
                <li>
                    <NavLink exact to ='/about'
                    className='nav-links'activeClassName='main-nav-active' >About</NavLink>
                </li>
                <li>
                    <NavLink exact to ='/services'
                    className='nav-links' activeClassName='main-nav-active' >Services</NavLink>
                </li>
                <li>
                    <NavLink  to ='/'
                    className='nav-links'  >Blog</NavLink>
                </li>
                <li>
                    <NavLink exact to ='/contactus'
                    className='nav-links' activeClassName='main-nav-active' >Contact Us</NavLink>
                </li>    
                </ul>
            </nav> */}

            
        </>
    )
}

export default Navbar

