

import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Figure, Card, Form,FormControl,Button } from 'react-bootstrap';
import { useParams, useRouteMatch, useLocation, useHistory, Link  } from 'react-router-dom';
import Blogimg from '../asset/img/blogpost1.jpeg';
import 'antd/dist/antd.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser, faClock, faCommentDots, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from 'antd';
import { postRequest, MD5Hash, BLOG_LIST, BASE_IMAGE_URL, 
  BLOG_VIEW,salt, BLOG_RECENT_POST,BLOG_CREATE_COMMENT, BLOG_COMMENT_LIST,BLOG_RECENT_COMMENTS,BLOG_CATEGORY_LIST,BLOG_SEARCH
} from '../util/api';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const SearchBlogViewList = (data) => {

  
  const location = useLocation()
  const [blogview, setBlogview] = useState([]);
  const [blogCommentList, setBlogCommentList] = useState([]);
  const [socialIcons, setSocialIcons] = useState({
    social:[]
  });

  const path = location.pathname.split('/');
  let id = path[2];

  const [recent_blogs, setRecentBlogs] = useState({
    recent:[]
  });

  useEffect(()=>{
    handleSubmit();
  },[id]);

  const [searchList, setSearchList] = useState([]);

  const [recent_comments, setRecentComments] = useState();
  const [blogCategoryList, setBlogCategoryList] = useState();
  const [blogSearch, setSearchValue] = useState({
    search_input:id,
    auth_token:""
  });
  const regex = /(<([^>]+)>)/ig;
  

  useEffect(() => {
    blog_view();
    recentPost();
    blog_comment_list();
    recentComments();
    categoryList();
  }, []);

 

  const blog_view = async () => {
    let auth_token = MD5Hash(id);
    let data = {
      "auth_token": auth_token,
      "blog_id":id,
    }
    var res = await postRequest(BLOG_VIEW, data);
    if (res.success) {
      setBlogview(res?.parameters?.data);
      setSocialIcons({
        ...socialIcons,
        social:res?.parameters?.data[0].socialmedia_icons?.split(',')
      })
    }
  }

  let history = useHistory();

  const recentPost = async () =>{
    let auth_token = MD5Hash('');
    let data = {
      "auth_token": auth_token,
    }
    var res = await postRequest(BLOG_RECENT_POST, data);
    if (res.success) {
      setRecentBlogs({
        ...recent_blogs,
        recent:res.parameters
      })
      
    }
  }

  const recentComments = async () =>{
    let auth_token = MD5Hash('');
    let data = {
      "auth_token": auth_token,
    }
    var res = await postRequest(BLOG_RECENT_COMMENTS, data);
    if (res.success) {
      setRecentComments(res?.parameters) 
    }
  }

  const categoryList = async () =>{
    let auth_token = MD5Hash('');
    let data = {
      "auth_token": auth_token,
    }
    var res = await postRequest(BLOG_CATEGORY_LIST, data);
    if (res.success) {
      setBlogCategoryList(res?.parameters) 
    }
  }



  const blog_comment_list = async () => {
    let auth_token = MD5Hash(id);
    let data = {
      "blog_id":id,
      "auth_token": auth_token,
    }
    var res = await postRequest(BLOG_COMMENT_LIST, data);
    if (res.success) {
      setBlogCommentList(res?.parameters);
    }
  }

  // const blog_search = async (e) => {
  //   blogSearch.auth_token = MD5Hash("");
  //   var res = await postRequest(BLOG_SEARCH, blogSearch);
  //   if (res.success) {
  //     // setBlogCommentList(res?.parameters);
  //   }
  // }


  const handleSubmit = async () =>{
    blogSearch.auth_token = MD5Hash("");
    var res = await postRequest(BLOG_SEARCH, blogSearch);
    if (res) {
      setSearchList(res);
    }
  }

  return (
    <body style={{backgroundColor: "#f5f5f5"}}>
    <div className="full-detail">
          <Helmet>
                <title>You searched for {id} Digidzn</title>
                <meta name="description" content="Worried about growth hacking marketing strategies for Campaigns? Be it Facebook Ads or Google Ads, we are pro in handling and marketing digital campaigns. We not only create campaigns for you but also track campaigns for you."/>
            </Helmet>
      <div className="explore-container">
        <div>
          <Container>
            <Row>
              <Col className='mt-10'>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
            <Col md={8} className='searchVal'>
            <div className='mt-5 '>
              <h4 className='search-txt'>Search Results for: {id} </h4>
            </div>
            </Col>
              <Col md={8} className='searchBgList'>
{
  searchList.parameters != null ? (
    searchList?.parameters?.data?.map((item, index)=>{
      return(
            <div>
                 {
                    item?.blog_image_url != null ? (
                      <img
                        width='100%'
                        src={BASE_IMAGE_URL+item?.blog_image_url}
                        alt='blog-img'
                      />
                    ) : (
                      <div className='no-img-found'>No image</div>
                    )}
                  <div className="">
                    <h4 className='blog_search_title'>{item?.blog_name}</h4>
                  {/* <div className='blog_search_des'>{item?.description.replace(regex, '')}</div> */}
                  <div className='img-size1 blog-des-search'>{ReactHtmlParser(item?.description)}</div>
                  <div className='readmore-div'><Link className="read-more-txt" to={`/Blog-view/${item.blog_id}`} >Read More</Link></div>
                  
                  </div>
            </div>
      )
        
    })
  ):(
    <div className='blog_error_msg'>{searchList.message}</div>
  )
  
}
                

                
   
                  
              </Col>
              <Col md={1}></Col>
              <Col md={3} className='top-space'>
                <div>
                  <input type="text" onChange={(e)=>{
                    setSearchValue({
                      ...blogSearch,
                      search_input:e.target.value
                    })
                    // blog_search(e)
                  }} placeholder="Search" className="search-ip"  /> 
                  
                  {/* <i class="fa fa-search searchBtn" onClick={() => history.push("/Searchblog")} aria-hidden="true"></i> */}
                  <Link className="read-more-txt" onClick={handleSubmit} to={`/Blog-search-view/${blogSearch.search_input}`} > 
                  <FontAwesomeIcon icon={faSearch} className="fa-seacrh-icon" size="lg" color="grey" /> 
                 </Link>
                </div>
                <div className='recent-div'>
                  <div className='recent-post-div'>Recent Posts</div>
                  {
                    recent_blogs?.recent?.map((item)=>{
                      return(
                        // <a className='txt-size'>{item?.blog_name}</a>
                        <p><a className="txt-size" href={`/Blog-view/${item.blog_id}`} >{item?.blog_name}</a></p>
                      )
                    })
                  }
                </div>

                {/* <div className='recent-div'>
                  <div className='recent-post-div'>Recent Comments</div>
                  {
                    recent_comments?.map((item)=>{
                      return(
                        <p><a className='txt-size'>{item?.comments}</a></p>
                      )
                    })
                  }
                </div> */}


                <div className='recent-div'>
                  <div className='recent-post-div'>Categories</div>
                  {
                    blogCategoryList?.map((item)=>{
                      return(
                        <p><a href={`/category-details/${item.id}`} className='txt-size'>{item?.category_name}</a></p>
                      )
                    })
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        

      </div>
    </div>
    </body>
  )
}

export default SearchBlogViewList




