import React from 'react'
import { useState, useEffect } from 'react';
import { Row, Col, Container, Figure, Card } from 'react-bootstrap';
import Result1 from '../asset/img/sec-10.jpg'
import Result2 from '../asset/img/sec-11.jpg'
import Blogimg1 from '../asset/img/blog-img1.png'
import Blogimg2 from '../asset/img/blog-img2.jpg'
import Blogimg3 from '../asset/img/blog-img3.png'
import Footer from '../Components/Footer';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { Link,useParams,useRouteMatch } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

const Services = () => {
   
    // console.log(bloglist);
    // const {
    //    Params: { blog_id },
    //   } = useRouteMatch('/blog/:blog_id');
    
    //   console.log(blog_id)
    // var blogId =  useParams()
    // debugger;
    return (
        <div>
            {/* section4 full growth */}
            <Helmet>
                <title>Digital marketing services | Grow your business | digiDZN</title>
                <meta name="description" content="Best Digital Marketing Company in Delhi NCR with certified digital marketing experts for all Onlline Marketing Services ✓SEO ✓Social Media Marketing ✓SMO ✓Advertising ✓eeCommerce and more." />
            </Helmet>
            <div>

                <Container>
                    <Row>
                        <Col className="text-center mt-5 heading-full-growth">
                            <h1>
                                Full Growth Capabilities

                            </h1>

                        </Col>
                    </Row>
                </Container>
            </div>

            
            <div className="full-growth-bg">
                <Container className="mt-2 ">
                    <Row xs="3">
                        <Col md={4} xs={12}>
                            <div className="full-growthcontent">
                                <h6>
                                    Academy
                                </h6>
                                <p>
                                    Come, learn & intern! digiDZN brings to you courses in
                                    Digital Marketing & eCommerce to make you industry ready.
                                    digiDZN helps you get placed post your training.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} xs={12}>
                            <Link to='/seo' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        SEO
                                    </h6>
                                    <p>
                                        Do you want to execute modern yet effective
                                        SEO to power your business strategy? Let us
                                        help you to deliver what matters for your industry!
                                    </p>
                                </div>
                            </Link>
                        </Col>


                        <Col md={4} xs={12}>
                            <Link to='/socialmedia' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        Social Media
                                    </h6>
                                    <p>
                                        Finding new ways to interact and engage with the audience of your brand?
                                        Still not sure how to execute it?
                                        Let us assist you with befitting strategy and approach.
                                    </p>
                                </div>
                            </Link>
                        </Col>


                        <Col md={4} xs={12}>
                            <Link to='/campaigns' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        Campaigns
                                    </h6>
                                    <p>
                                        Do you know what is essential to get quality leads?
                                        A good campaign strategy! Let us plan some terrific
                                        campaign strategies to make your brand go viral.
                                    </p>
                                </div>
                            </Link>
                        </Col>
                        <Col md={4} xs={12}>
                            <div className="full-growthcontent">
                                <h6>
                                    E-Commerce
                                </h6>
                                <p>
                                    Figuring out ways to generate sales on your e-commerce platforms?
                                    Well, we are here to expand your e-commerce
                                    business following all the algorithms and guidelines.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} xs={12}>
                            <div className="full-growthcontent">
                                <h6>
                                    PR
                                </h6>
                                <p>
                                    Do you know PR strategy is vital for a business
                                    to establish a strong reputation? With the right
                                    PR strategy, brand visibility can be highly effective.
                                </p>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col className="text-center mt-5 mb-5">
                            <Link to="/results"> <button className="growth-button">Full Growth Capabilities</button></Link>
                        </Col>
                    </Row>
                </Container>


            </div>
           
            <div className="bg-result mt-1">
                <Container>
                    <Row>
                        <Col className="text-center mt-1 heading-full-growth">
                            <h1>
                                Consistently Delivering Results
                            </h1>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className="mt-5 ">
                            <div className="bgcolor-result">
                                <img
                                    className="d-block img-result border-result"
                                    src={Result1}
                                    alt="Sec-10"
                                />
                            </div>
                        </Col>
                        <Col className="mt-5">
                            <div className="bgcolor-result">
                                <img
                                    className="d-block border-result img-result"
                                    src={Result2}
                                    alt="Sec-10"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center mt-5">
                            <Link to="/results"><button className="growth-button">Read more</button></Link>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className="text-center mt-5 heading-full-growth">
                            <h1>
                                Learning Bytes
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Card className="border-card" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" class="blog-img img-fluid" src={Blogimg1} />
                                    <Card.Title className="mt-2 blog-title">

                                        HOW TO USE FACEBOOK VIDEO ADS TO GROW YOUR BUSINESS</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        September 24, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        We’re presuming you already know that videos are the way
                                        to go and that you’ll need to run Facebook advertising. And this implies that you’re
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-card" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" class="blog-img img-fluid" src={Blogimg2} />
                                    <Card.Title className="mt-2 blog-title">Are Facebook ads worth it? (Yes, here’s why)</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        September 20, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        Facebook seems to have run its course. With people moving towards
                                        newer social media platforms and with all the false media, it may look like
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-card" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" class="blog-img img-fluid " src={Blogimg3} />
                                    <Card.Title className="mt-2 blog-title">Best Apps for Social Media Marketing 2021</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        August 28, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        Social media is necessary for both marketers and consumers,
                                        whether you like it or not. People quickly realized how crucial it was to maintain a
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section6-ready to kickoff  http://www.w3.org/2000/svg*/}
            <div class="custom-shape-divider-top-1635236144 mt-10">
                <svg data-name="Layer 1" xmlns="" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>
            <div className="ready-kick-bg">

                <Container >
                    <Row>
                        <Col className="text-center sec-6-head">
                            <h1>Ready To Kick Off Your Growth Journey</h1>
                            <Link to="/contact-us"><button className="ready-kick-btn mt-5">SPEAK TO DIGIDZN</button></Link>
                        </Col>

                    </Row>
                </Container>
            </div>
            <Footer />
        </div>
    )




}

export default Services
