import React from 'react'
import { useState, useEffect } from 'react';
import { Row, Col, Container, Figure, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import MoreDeets from "../pages/card";
import Digivid from '../Video/digidzn-vid.4ac3484e.gif'
import Icon1 from '../asset/img/Icon-1.png'
import Icon3 from '../asset/img/Icon-3.png'
import Icon4 from '../asset/img/Icon-4.png'
import Icon5 from '../asset/img/Icon-5.png'
import Icon6 from '../asset/img/Icon-6.png'
import Icon7 from '../asset/img/Icon-7.png'
import Icon8 from '../asset/img/Icon-8.png'
import Icon9 from '../asset/img/Icon-9.png'
import Caro1 from '../asset/img/1-6.webp'
import Caro2 from '../asset/img/2-5.webp'
import Caro3 from '../asset/img/3-4.jpg'
import Caro4 from '../asset/img/4-3.webp'
import Caro5 from '../asset/img/5-2.webp'
import Caro6 from '../asset/img/6-1.jpg'
import logo from '../asset/img/logo.png'
import Sec1 from '../asset/img/logo-1-1.png'
import Sec2 from '../asset/img/sec3.png'
import Sec3 from '../asset/img/logo2-1.png'
import Testimonial from './testimonial';
import Result1 from '../asset/img/sec-10.jpg'
import Result2 from '../asset/img/sec-11.jpg'
import Blogimg1 from '../asset/img/blog-img1.png'
import Blogimg2 from '../asset/img/blog-img2.jpg'
import Blogimg3 from '../asset/img/blog-img3.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from 'react-bootstrap/Button'
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from 'react-icons/fa';
import Footer from '../Components/Footer';
import Carousel1 from '../Components/carousel1';
import Animation from './Animation';
import { Helmet } from 'react-helmet-async';



const Home = () => {


    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoPlay: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const client = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };



    return (


        <div >
            <Helmet>
                <title>Best Digital Marketing Agency in Delhi, NCR | digiDZN</title>
                <meta name="description" content="Welcome to digiDZN, a leading agency for digital marketing company in Delhi. We utilize our digital marketing experience and world-class knowledge to help our customers to grow in their industry." />
            </Helmet>
            {/* banner text and video */}
            <div className=" homepage-container1 ">
                <Container className='homepage-container1'>
                    <Row>
                        <Col xs={12} md={7} className="homepage-text1 mt-5">
                            <h1 className="homepage-mob">
                                We help you with your
                            </h1>
                            {/* <div class="title">
                                    <div class="word rhythm">
                                    <span>C</span>
                                    <span>o</span>
                                    <span>n</span>
                                    <span>v</span>
                                    <span>e</span>
                                    <span>r</span>
                                    <span>s</span>
                                    <span>i</span>
                                    <span>o</span>
                                    <span>n</span>
                                    </div>
                                    <div class="word city">
                                    <span>U</span>
                                    <span>s</span>
                                    <span>e</span>
                                    <span>r</span>
                                    <span>b</span>
                                    <span>a</span>
                                    <span>s</span>
                                    <span>e</span>
                                    </div>
                                    <div class="word voice">
                                    <span>L</span>
                                    <span>e</span>
                                    <span>a</span>
                                    <span>d</span>
                                    <span>s</span>
                                    </div>
                                    <div class="word sales">
                                    <span>S</span>
                                    <span>a</span>
                                    <span>l</span>
                                    <span>e</span>
                                    <span>s</span>
                                    </div>
                                    <div class="word brands">
                                    <span>B</span>
                                    <span>r</span>
                                    <span>a</span>
                                    <span>n</span>
                                    <span>d</span>
                                    <span>s</span>
                                    </div>
                                </div>    */}
                            <div>


                                <Animation />



                            </div>

                            {/* <h1 style={{color:'#FCEB5D'}}>Conversion</h1> */}
                        </Col>
                        <Col xs={12} md={5} className="video-homepage  text-center" >
                            <img src={Digivid} className="video-mob" />
                                
                            
                        </Col>
                    </Row>
                </Container>
                {/* banner icon-section */}
                <Container>
                    <Row>
                        <Col md={6} lg={3} className='homepage-text2'>
                            <h4 style={{ color: 'white' }}>
                                Loved & trusted by Small <br />& Medium Businesses
                            </h4>
                            <p>
                                To assist businesses ever <br />
                                explore the power of internet &<br />
                                convert contemporary online<br />
                                mediums into a profitable<br />
                                distribution channel working in<br />
                                sync with offline marketing<br />
                                efforts
                            </p>
                        </Col>
                        <Col md={6} lg={9} className='homepage-icons ' >
                            <Row xs={4} className='text-center row-cols-md-2 row-cols-lg-4 padding-icons'>
                                <Col className="icon-1">
                                    <img
                                        className="d-block img-icon"

                                        src={Icon1}
                                        alt="Icon1"
                                    />
                                    <Figure.Caption className="gallery-caption">
                                        Travelling
                                    </Figure.Caption>

                                </Col>
                                <Col className="icon-1">
                                    <img
                                        className="d-block img-icon"

                                        src={Icon3}
                                        alt="Icon3"
                                    />
                                    <Figure.Caption className="gallery-caption"> Fashion </Figure.Caption>
                                </Col>
                                <Col className="icon-1 ">
                                    <img
                                        className="d-block img-icon"

                                        src={Icon4}
                                        alt="Icon4"
                                    />
                                    <Figure.Caption className="gallery-caption"> FMCG</Figure.Caption>
                                </Col>
                                <Col className="icon-1">
                                    <img
                                        className="d-block img-icon"

                                        src={Icon5}
                                        alt="Icon5"
                                    />
                                    <Figure.Caption className="gallery-caption">Real Estate</Figure.Caption>
                                </Col>
                                <Col>
                                    <img
                                        className="d-block img-icon"

                                        src={Icon6}
                                        alt="Icon6"
                                    />
                                    <Figure.Caption className="gallery-caption"> Education</Figure.Caption>

                                </Col>
                                <Col>
                                    <img
                                        className="d-block img-icon"

                                        src={Icon7}
                                        alt="Icon7"
                                    />
                                    <Figure.Caption className="gallery-caption">Corporate</Figure.Caption>
                                </Col>
                                <Col>
                                    <img
                                        className="d-block img-icon"

                                        src={Icon8}
                                        alt="Icon8"
                                    />
                                    <Figure.Caption className="gallery-caption">Photography</Figure.Caption>
                                </Col>
                                <Col>
                                    <img
                                        className="d-block img-icon"

                                        src={Icon9}
                                        alt="Icon9"
                                    />
                                    <Figure.Caption className="gallery-caption">Health and fitness</Figure.Caption>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section 2 */}
            <div className="section2">
                <Container>
                    <Row>
                        <Col md={6} className="p-4 mt-5 sec-2-heading">
                            <h1>If you are not <mark className="mark-words">ONLINE</mark></h1><br />
                            <h1>You are <mark className="mark-words">OFF THE LINE</mark></h1>
                        </Col>

                        <Col md={6} className="p-4 sec-2-paragraph">
                            <p>
                                digiDZN helps small & medium Enterprises ease into the world of digital. We allow them to understand what it means to have an online presence, and how it needs to be treated as an extension of their offline business. More so how they can reach out to their target audience online instead of waiting for them to reach out to the business. Keep the never ending flow of leads, and business coming
                            </p>
                        </Col>

                    </Row>
                </Container>
            </div>
            <Carousel1 />
            {/* section-3 carousel */}
            {/* <div className="section3-carousel">
                <Container>
                    <Row>
                        <Col className="text-center sec-2-carousel mt-5">
                            <h2>
                                Result Oriented Branding & Digital Transformation
                            </h2>

                            <Slider {...settings}>
                                <div>
                                    <img
                                        className="d-block img-icon"
                                        src={Caro1}
                                        alt="Caro1"
                                    />

                                </div>
                                <div>
                                    <img
                                        className="d-block img-icon"
                                        src={Caro2}
                                        alt="Caro2"
                                    />
                                </div>
                                <div>
                                    <img
                                        className="d-block img-icon"
                                        src={Caro3}
                                        alt="Caro3"
                                    />
                                </div>
                                <div>
                                    <img
                                        className="d-block img-icon"
                                        src={Caro4}
                                        alt="Caro4"
                                    />
                                </div>
                                <div>
                                    <img
                                        className="d-block img-icon"
                                        src={Caro5}
                                        alt="Caro5"
                                    />
                                </div>
                                <div>
                                    <img
                                        className="d-block img-icon"
                                        src={Caro6}
                                        alt="Caro6"
                                    />
                                </div>

                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div> */}
            {/* section-4 logo */}
            <div className="section2 mt-5">
                <Container>
                    <Row >
                        <Col md={1}></Col>
                        <Col md={5} className="mt-5 sec-2-heading">
                            <h1>It Feels good</h1><br />
                            <h1><mark className="mark-words">To be talked about</mark></h1>
                        </Col>

                        <Col md={6} className="p-4 sec-2-paragraph">
                            <Row>
                                <Col xs={4} md={4}>
                                    <img
                                        className="d-block img-icon"
                                        src={Sec1}
                                        alt="Sec1"
                                    />
                                </Col>
                                <Col xs={4} md={4}>
                                    <img
                                        className="d-block img-icon"
                                        src={Sec2}
                                        alt="Sec2"
                                    />
                                </Col>
                                <Col xs={4} md={4}>
                                    <img
                                        className="d-block img-icon"
                                        src={Sec3}
                                        alt="Sec3"
                                    />
                                </Col>
                            </Row>

                            {/* Columns are always 50% wide, on mobile and desktop */}
                            <Row>
                                <Col xs={12} className="mt-3 ">
                                    <p>
                                        A good offline-online synergy approach
                                        ensures that the customer gets maximum
                                        RoI of his rupee spent. From online marketing
                                        to holding offline events to making a presence
                                        in the important events, digDZN has been there everywhere.
                                    </p>
                                </Col>

                            </Row>
                        </Col>

                    </Row>
                </Container>
            </div>
            {/* section4-client testimonial */}
            <Testimonial />

            {/* section4 full growth */}
            <div>
                <Container>
                    <Row>
                        <Col className="text-center heading-full-growth">
                            <h1>
                                Full Growth Capabilities
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="full-growth-bg">
                <Container className="mt-2 ">
                    <Row xs="3">
                        <Col md={4} xs={12}>
                            <div className="full-growthcontent">
                                <h6>
                                    Academy
                                </h6>
                                <p>
                                    Come, learn & intern! digiDZN brings to you courses in
                                    Digital Marketing & eCommerce to make you industry ready.
                                    digiDZN helps you get placed post your training.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} xs={12}>
                            <Link to='/seo' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        SEO
                                    </h6>
                                    <p>
                                        Do you want to execute modern yet effective
                                        SEO to power your business strategy? Let us
                                        help you to deliver what matters for your industry!
                                    </p>
                                </div>
                            </Link>
                        </Col>


                        <Col md={4} xs={12}>
                            <Link to='/social-media' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        Social Media
                                    </h6>
                                    <p>
                                        Finding new ways to interact and engage with the audience of your brand?
                                        Still not sure how to execute it?
                                        Let us assist you with befitting strategy and approach.
                                    </p>
                                </div>
                            </Link>
                        </Col>


                        <Col md={4} xs={12}>
                            <Link to='/campaigns' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        Campaigns
                                    </h6>
                                    <p>
                                        Do you know what is essential to get quality leads?
                                        A good campaign strategy! Let us plan some terrific
                                        campaign strategies to make your brand go viral.
                                    </p>
                                </div>
                            </Link>
                        </Col>

                        <Col md={4} xs={12}>
                            <div className="full-growthcontent">
                                <h6>
                                    E-Commerce
                                </h6>
                                <p>
                                    Figuring out ways to generate sales on your e-commerce platforms?
                                    Well, we are here to expand your e-commerce
                                    business following all the algorithms and guidelines.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} xs={12}>
                            <div className="full-growthcontent">
                                <h6>
                                    PR
                                </h6>
                                <p>
                                    Do you know PR strategy is vital for a business
                                    to establish a strong reputation? With the right
                                    PR strategy, brand visibility can be highly effective.
                                </p>
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col className="text-center mt-5 mb-5">
                            <Link to='services'> <button className="growth-button">Full Growth Capabilities</button> </Link>
                        </Col>
                    </Row>
                </Container>


            </div>
            {/* section-5-result */}
            <div className="bg-result mt-1">
                <Container>
                    <Row>
                        <Col className="text-center mt-1 heading-full-growth">
                            <h1>
                                Consistently Delivering Results
                            </h1>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className="mt-5 " md={6} xs={12}>
                            <div className="bgcolor-result">
                                <img
                                    className="d-block img-result border-result"
                                    src={Result1}
                                    alt="Sec-10"
                                />
                            </div>
                        </Col>
                        <Col className="mt-5" md={6} xs={12}>
                            <div className="bgcolor-result">
                                <img
                                    className="d-block border-result img-result"
                                    src={Result2}
                                    alt="Sec-10"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center mt-5">
                            <Link to='results'> <button className="growth-button">Read more</button></Link>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className="text-center mt-5 heading-full-growth">
                            <h1>
                                Learning Bytes
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container>
                    <Row>
                        <Col md={4}>
                            <Card className="border-card" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" class="blog-img img-fluid" src={Blogimg1} />
                                    <Card.Title className="mt-2 blog-title">HOW TO USE FACEBOOK VIDEO ADS TO GROW YOUR BUSINESS</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        September 24, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        We’re presuming you already know that videos are the way
                                        to go and that you’ll need to run Facebook advertising. And this implies that you’re
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-card" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" class="blog-img img-fluid" src={Blogimg2} />
                                    <Card.Title className="mt-2 blog-title">Are Facebook ads worth it? (Yes, here’s why)</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        September 20, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        Facebook seems to have run its course. With people moving towards
                                        newer social media platforms and with all the false media, it may look like
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-card" style={{ width: "100%" }}>
                                <Card.Body>
                                    <Card.Img variant="top" class="blog-img img-fluid " src={Blogimg3} />
                                    <Card.Title className="mt-2 blog-title">Best Apps for Social Media Marketing 2021</Card.Title>
                                    <Card.Subtitle className="mb-2 mt-2 blog-sub">
                                        August 28, 2021
                                    </Card.Subtitle>
                                    <Card.Text className="mb-2 mt-2 blog-text">
                                        Social media is necessary for both marketers and consumers,
                                        whether you like it or not. People quickly realized how crucial it was to maintain a
                                    </Card.Text>
                                    <Card.Link className="blog-link" href="#"> Read More <FaAngleDoubleRight></FaAngleDoubleRight></Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* section6-ready to kickoff  http://www.w3.org/2000/svg*/}
            <div class="custom-shape-divider-top-1635236144 mt-10">
                <svg data-name="Layer 1" xmlns="" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>
            <div className="ready-kick-bg">

                <Container >
                    <Row>
                        <Col className="text-center sec-6-head">
                            <h1>Ready To Kick Off Your Growth Journey</h1>
                            <Link to="/contact-us"> <button className="ready-kick-btn mt-5">SPEAK TO DIGIDZN</button></Link>
                        </Col>

                    </Row>
                </Container>
            </div>
            <Footer />

        </div>
    )
}


export default Home
