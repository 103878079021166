import React from 'react'
import './Footer.css'
import { Col, Container, Row } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import logo from '../asset/img/logo.png'
import {FaFacebookSquare, FaInstagram, FaLinkedin, FaTwitterSquare} from 'react-icons/fa'
function Footer() {
    
    return (
        <div>
            <footer className="footer-digidzn">
                
            <Container fluid >
                    <Row>
                    {/* <hr style={{color:'white'}}/> */}
                        <Col md={2} lg={2} className="alignment-foot-logo ">
                        <Link to = '/' className='nav-logo '>
                <img
                className="d-block w-30 h-60 text-center align-items-center"
                width="174"
                src={logo}
                alt="logo"
                />
              </Link>
                        </Col>
                        
                        <Col md={2} xs={6} lg={2} className=" footer-area-1 mt-5">
                        
                    
                     <ul className=" footer-menu">
                         <li className='footer-links-heading'>Company</li>
                         <li  style={{marginTop:'10px'}}><Link to = '/' className='footer-links'>Home</Link></li>
                        
                        
                        <li><Link to ='/about' className='footer-links'>About</Link></li>
                        
                        <li><Link to ='/services' className='footer-links'>Services</Link></li>
                    
                        <li><Link to = '/blog' className='footer-links'>Blogs</Link></li>     
                         
                        <li><Link to ='/contact-us' className='footer-links'>Contact Us</Link></li>
                    </ul>
                        </Col>
                        
                        <Col md={3} lg={2} xs={6} className="mt-5">
                            <ul className=" footer-menu">
                                <li className='footer-links-heading'>Services</li>
                                <li style={{marginTop:'10px'}}><Link to = '/digitalmarketinginternship' className='footer-links'>D3M Academy</Link></li>
                                
                                
                                <li><Link to = '/seo' className='footer-links'>SEO</Link></li>
                                
                                <li><Link to = '/social-media' className='footer-links'>Social Media</Link></li>
                            
                                <li><Link to = '/campaigns' className='footer-links'>Campaigns</Link></li>     
                                
                                <li><Link to = '/ecommerce' className='footer-links'>E-Commerce</Link></li>

                                <li><Link to = '/pr' className='footer-links'>PR</Link></li>
                            </ul>
                        </Col>
                        
                        
                        <Col md={4} lg={4} className="mt-lg-5 mt-xs-3 mt-sm-3">
                             <ul className=" footer-menu">
                                <li className='footer-links-heading'>Recent Posts</li>
                                <li style={{marginTop:'10px'}}><Link to = '/' className='footer-links'>HOW TO USE FACEBOOK VIDEO ADS TO GROW YOUR BUSINESS</Link></li>
                                
                                
                                <li><Link to = '/' className='footer-links'>Are Facebook ads worth it? (Yes, here’s why)</Link></li>
                                
                                <li><Link to = '/' className='footer-links'>Best Apps for Social Media Marketing 2021</Link></li>
                            
                                <li><Link to = '/' className='footer-links'>Digital Marketing Google Course</Link></li>     
                                
                                <li><Link to = '/' className='footer-links'>Social Media For Small Businesses</Link></li>

                            </ul>
                        </Col>
                        
                        <Col md={4} lg={2} className="footer-icon">
                            <ul className="inline-icon">
                                <li>
                                   <a href="https://www.facebook.com/digiDZN" className="footer-icon-link" target="_blank"><FaFacebookSquare></FaFacebookSquare></a>
                                </li>
                                <li>
                                <a href="https://www.instagram.com/digi_dzn/" className="footer-icon-link" target="_blank"><FaInstagram></FaInstagram></a>
                                </li>
                                <li>
                                <a href="https://www.linkedin.com/company/digidzn/" className="footer-icon-link" target="_blank"><FaLinkedin></FaLinkedin></a>
                                </li>
                                <li>
                                <a href="https://twitter.com/digi_dzn?lang=en" className="footer-icon-link" target="_blank"><FaTwitterSquare></FaTwitterSquare></a>
                                </li>
                            </ul>    
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <Col md={3} lg={3} className="footer-bottom-text">
                            <p>
                        © 2021. All Rights Reserved.
                        </p>
                        </Col>
                        <Col md={2} className="mob-dis-col">
                        </Col>
                        <Col md={5} className="footer-bottom-text">
                        <p>Address - G-4, 1st Floor, Lawrence Road Industrial Area.      Phone Number - 9868877018</p>
                        </Col>
                        <Col md={1}></Col>

                    </Row>
                    </Container>
            </footer>

            
        </div>
    )
}

export default Footer
